import { FETCH_CONTENT_SCHEMA_SUCCEEDED, UPDATE_CONTENT_MODEL_CAMPAIGN, UPDATE_CONTENT_MODEL_AI_FEATURES, UPDATE_WEBVERSION_ENABLED, UPDATE_CONTENT_MODEL_DOMAIN, UPDATE_CONTENT_MODEL_EXPIRY_DATE, UPDATE_CONTENT_MODEL_EXPIRY_ENABLED, UPDATE_CONTENT_MODEL_EXPIRY_REDIRECT_URL, UPDATE_CONTENT_MODEL_HTML_TITLE, UPDATE_CONTENT_MODEL_META_DESCRIPTION, UPDATE_CONTENT_MODEL_NAME, UPDATE_CONTENT_MODEL_SLUG, UPDATE_CONTENT_MODEL_CANONICAL_URL, UPDATE_PAGE_CONTENT_MODEL_CSS, UPDATE_CONTENT_MODEL_TEMPLATE_PATH, FETCH_CONTENT_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
import { fromJS } from 'immutable';
// @ts-expect-error not yet migrated
import { slugify } from 'ContentEditorUI/utils/helpers';
import produce from 'immer';
import get from 'hs-lodash/get';

// Can be migrated after migrating `pages` slice

export const initialState = {
  aiFeatures: null,
  campaign: null,
  campaignName: null,
  campaignUtm: null,
  domain: '',
  htmlTitle: null,
  id: 0,
  isPublished: false,
  language: null,
  linkRelCanonicalUrl: null,
  mabVariant: false,
  metaDescription: '',
  name: '',
  pageExpiryDate: null,
  pageExpiryEnabled: null,
  pageExpiryRedirectId: null,
  pageExpiryRedirectUrl: null,
  slug: '',
  previewKey: '',
  state: 'DRAFT',
  subcategory: '',
  templatePath: ''
};

// NOTE This reducer ONLY contains fields that will be saved to the content object
// 'base' means it only holds fields that are shared by at least 2 projects (PEUI/BEUI/EEUI/EDNDUI)
// app-specific content model fields belong in <app>ContentModelReducer
// app-settings (UI state) fields belong in either baseSettingsReducer or <app>SettingsReducer
// miscellaneous shared fields that don't get saved go in contentReadOnlyDataReducer
export const baseContentModelReducerAsJSObject = (state = initialState, {
  type,
  payload,
  response
}) => produce(state, draft => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          content
        } = response;
        draft = Object.assign(draft, {
          aiFeatures: content.aifeatures || {
            generatedByAi: [],
            generatedWithAi: []
          },
          campaign: content.campaign,
          campaignName: content.campaignName,
          campaignUtm: content.campaignUtm,
          domain: content.domain,
          htmlTitle: content.htmlTitle || '',
          id: content.id,
          isPublished: content.isPublished,
          language: content.language,
          linkRelCanonicalUrl: content.linkRelCanonicalUrl || '',
          mabVariant: content.mabVariant,
          metaDescription: content.metaDescription || '',
          name: content.name,
          pageExpiryDate: content.pageExpiryDate,
          pageExpiryEnabled: content.pageExpiryEnabled,
          pageExpiryRedirectId: content.pageExpiryRedirectId,
          pageExpiryRedirectUrl: content.pageExpiryRedirectUrl,
          slug: content.slug,
          previewKey: content.previewKey,
          state: content.state,
          subcategory: content.subcategory,
          templatePath: content.templatePath
        });
        return draft;
      }
    case UPDATE_CONTENT_MODEL_CAMPAIGN:
      return Object.assign(draft, {
        campaign: payload ? payload.guid : null,
        campaignName: payload ? payload.display_name : null,
        campaignUtm: payload ? payload.utm : null
      });
    case UPDATE_WEBVERSION_ENABLED:
      draft.domain = '';
      return draft;
    case UPDATE_CONTENT_MODEL_DOMAIN:
      draft.domain = payload.domain;
      return draft;
    case UPDATE_CONTENT_MODEL_EXPIRY_DATE:
      draft.pageExpiryDate = payload;
      return draft;
    case UPDATE_CONTENT_MODEL_EXPIRY_ENABLED:
      draft.pageExpiryEnabled = payload;
      return draft;
    case UPDATE_CONTENT_MODEL_EXPIRY_REDIRECT_URL:
      {
        const {
          url,
          id,
          pages
        } = payload;
        if (url && id) {
          return Object.assign(draft, {
            pageExpiryRedirectId: id,
            pageExpiryRedirectUrl: url
          });
        }
        let newId = url ? 0 : null; // ID is 0 for external urls
        const availableRedirectPage = pages.find(page => page.get('url') === url);
        if (availableRedirectPage) {
          newId = availableRedirectPage.get('id');
        }
        return Object.assign(draft, {
          pageExpiryRedirectId: newId,
          pageExpiryRedirectUrl: url
        });
      }
    case UPDATE_CONTENT_MODEL_HTML_TITLE:
      draft.htmlTitle = payload;
      return draft;
    case UPDATE_CONTENT_MODEL_META_DESCRIPTION:
      draft.metaDescription = payload;
      return draft;
    case UPDATE_CONTENT_MODEL_NAME:
      draft.name = payload;
      return draft;
    case UPDATE_CONTENT_MODEL_SLUG:
      draft.slug = slugify(payload);
      return draft;
    case UPDATE_CONTENT_MODEL_CANONICAL_URL:
      draft.linkRelCanonicalUrl = payload;
      return draft;
    case UPDATE_PAGE_CONTENT_MODEL_CSS:
      return Object.assign(draft, {
        css: payload.css,
        cssText: payload.cssText
      });
    case UPDATE_CONTENT_MODEL_TEMPLATE_PATH:
      draft.templatePath = payload;
      return draft;
    case UPDATE_CONTENT_MODEL_AI_FEATURES:
      {
        const {
          flagType,
          flags
        } = payload;
        if (!draft.aiFeatures) {
          draft.aiFeatures = {
            generatedByAi: [],
            generatedWithAi: []
          };
        }
        const addFlags = array => Array.from(new Set([...array, ...flags]));
        const removeFlags = array => array.filter(flag => !flags.includes(flag));
        const currentGeneratedByAI = get(draft, ['aiFeatures', 'generatedByAi']);
        const currentGeneratedWithAI = get(draft, ['aiFeatures', 'generatedWithAi']);
        const newGeneratedByAI = flagType === 'generatedByAi' ? addFlags(currentGeneratedByAI) : removeFlags(currentGeneratedByAI);
        const newGeneratedWithAI = flagType === 'generatedWithAi' ? addFlags(currentGeneratedWithAI) : removeFlags(currentGeneratedWithAI);
        draft.aiFeatures.generatedByAi = newGeneratedByAI;
        draft.aiFeatures.generatedWithAi = newGeneratedWithAI;
        return draft;
      }
    case FETCH_CONTENT_SUCCEEDED:
      draft.state = payload.state;
      return draft;
    default:
      return draft;
  }
});

// NOTE This reducer ONLY contains fields that will be saved to the content object
// 'base' means it only holds fields that are shared by at least 2 projects (PEUI/BEUI/EEUI/EDNDUI)
// app-specific content model fields belong in <app>ContentModelReducer
// app-settings (UI state) fields belong in either baseSettingsReducer or <app>SettingsReducer
// miscellaneous shared fields that don't get saved go in contentReadOnlyDataReducer
const baseContentModelReducer = (state = fromJS(initialState), {
  type,
  payload,
  response
}) => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          content
        } = response;
        state = state.merge({
          aiFeatures: content.aifeatures || {
            generatedByAi: [],
            generatedWithAi: []
          },
          campaign: content.campaign,
          campaignName: content.campaignName,
          campaignUtm: content.campaignUtm,
          domain: content.domain,
          htmlTitle: content.htmlTitle || '',
          id: content.id,
          isPublished: content.isPublished,
          language: content.language,
          linkRelCanonicalUrl: content.linkRelCanonicalUrl || '',
          mabVariant: content.mabVariant,
          metaDescription: content.metaDescription || '',
          name: content.name,
          pageExpiryDate: content.pageExpiryDate,
          pageExpiryEnabled: content.pageExpiryEnabled,
          pageExpiryRedirectId: content.pageExpiryRedirectId,
          pageExpiryRedirectUrl: content.pageExpiryRedirectUrl,
          slug: content.slug,
          previewKey: content.previewKey,
          state: content.state,
          subcategory: content.subcategory,
          templatePath: content.templatePath
        });

        // TODO Prevent merge from forcing JS objects/arrays into Immutable objects (older versions of
        // immutable-js do this) for `css`, `publicAccessRules`, and `publicAccessRules` ???

        return state;
      }
    case UPDATE_CONTENT_MODEL_CAMPAIGN:
      return state.merge({
        campaign: payload ? payload.guid : null,
        campaignName: payload ? payload.display_name : null,
        campaignUtm: payload ? payload.utm : null
      });
    case UPDATE_WEBVERSION_ENABLED:
      return state.set('domain', '');
    case UPDATE_CONTENT_MODEL_DOMAIN:
      return state.set('domain', payload.domain);
    case UPDATE_CONTENT_MODEL_EXPIRY_DATE:
      return state.set('pageExpiryDate', payload);
    case UPDATE_CONTENT_MODEL_EXPIRY_ENABLED:
      return state.set('pageExpiryEnabled', payload);
    case UPDATE_CONTENT_MODEL_EXPIRY_REDIRECT_URL:
      {
        const {
          url,
          id,
          pages
        } = payload;
        if (url && id) {
          return state.merge({
            pageExpiryRedirectId: id,
            pageExpiryRedirectUrl: url
          });
        }
        let newId = url ? 0 : null; // ID is 0 for external urls
        const availableRedirectPage = pages.find(page => page.get('url') === url);
        if (availableRedirectPage) {
          newId = availableRedirectPage.get('id');
        }
        return state.merge({
          pageExpiryRedirectId: newId,
          pageExpiryRedirectUrl: url
        });
      }
    case UPDATE_CONTENT_MODEL_HTML_TITLE:
      return state.set('htmlTitle', payload);
    case UPDATE_CONTENT_MODEL_META_DESCRIPTION:
      return state.set('metaDescription', payload);
    case UPDATE_CONTENT_MODEL_NAME:
      return state.set('name', payload);
    case UPDATE_CONTENT_MODEL_SLUG:
      return state.set('slug', slugify(payload));
    case UPDATE_CONTENT_MODEL_CANONICAL_URL:
      return state.set('linkRelCanonicalUrl', payload);
    case UPDATE_PAGE_CONTENT_MODEL_CSS:
      return state.merge({
        css: payload.css,
        cssText: payload.cssText
      });
    case UPDATE_CONTENT_MODEL_TEMPLATE_PATH:
      return state.set('templatePath', payload);
    case UPDATE_CONTENT_MODEL_AI_FEATURES:
      {
        const {
          flagType,
          flags
        } = payload;
        const addFlags = array => Array.from(new Set([...array, ...flags]));
        const removeFlags = array => array.filter(flag => !flags.includes(flag));
        const currentGeneratedByAI = state.getIn(['aiFeatures', 'generatedByAi']);
        const currentGeneratedWithAI = state.getIn(['aiFeatures', 'generatedWithAi']);
        const newGeneratedByAI = flagType === 'generatedByAi' ? addFlags(currentGeneratedByAI) : removeFlags(currentGeneratedByAI);
        const newGeneratedWithAI = flagType === 'generatedWithAi' ? addFlags(currentGeneratedWithAI) : removeFlags(currentGeneratedWithAI);
        return state.setIn(['aiFeatures', 'generatedByAi'], newGeneratedByAI).setIn(['aiFeatures', 'generatedWithAi'], newGeneratedWithAI);
      }
    case FETCH_CONTENT_SUCCEEDED:
      return state.set('state', payload.state);
    default:
      return state;
  }
};
export default baseContentModelReducer;