import { useCallback, useContext } from 'react';
import { CopilotChannelContext } from '../CopilotChannelContext';
import { validateEnvironment } from '../../utils/validateEnvironment';
export function useSendOnCopilotChannel(topic) {
  const {
    channel
  } = useContext(CopilotChannelContext);
  return useCallback(data => {
    if (!channel) {
      return;
    }
    validateEnvironment(topic);
    channel.postToTopic(topic, data);
  }, [channel, topic]);
}