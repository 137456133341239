import { uniqueId } from '../utils/uniqueId';
function generateUniqueKeyFor(keyedMap) {
  let key;
  do {
    key = uniqueId();
  } while (keyedMap[key] !== undefined);
  return key;
}

// By wrapping the callback we get a unique instance of the callback that can be identified later.
// This handles the case of the same function being subscribed multiple times
function createWrappedCallback(callback) {
  return data => {
    return callback(data);
  };
}

/**
 * It's highly recommended to never use this class directly, instead you should
 * rely on createCopilotChannel. CopilotChannel uses the BroadcastChannel
 * browser API which is supported in almost every browser but not all of them.
 * Because of this it's better to use the creator function which does an
 * existance check before trying to make the channel.
 */
class CopilotChannel {
  // used to clean up timeouts that have been set up on unmount

  constructor({
    __ENABLE_TEST_MODE
  } = {}) {
    this.addStateListener = listener => {
      this.stateListeners.push(listener);
      const unsubscribeKey = this.generateUniqueUnsubscribeKey();
      this.stateUnsubscribeMap[unsubscribeKey] = listener;
      return () => {
        return this.removeStateListener(unsubscribeKey);
      };
    };
    this.getState = () => {
      return this.state;
    };
    this.setState = partialState => {
      this.state = Object.assign({}, this.state, partialState);
      this.stateListeners.forEach(listener => listener(this.state));
    };
    this.getMessageLog = () => {
      return this.messageLog;
    };
    this.postToTopic = (topic, data) => {
      const message = {
        payload: data,
        topic
      };
      this.deliver(message);
    };
    this.deliver = message => {
      const {
        payload,
        topic
      } = message;

      // Track messages in test mode so we can compare against them in tests
      if (this.__ENABLE_TEST_MODE) {
        this.messageLog.push({
          data: payload,
          topic
        });
      }

      // We're intentionally broadening type check here because we lose type
      // safety when we go over the broadcast channel. The hooks that add handlers
      // ensure they have type safety so we don't need to do it here.
      const topicHandlers = this.topicToHandlers[topic] || [];
      topicHandlers.forEach(handler => {
        setTimeout(() => {
          handler(payload);
        }, 0);
      });
    };
    this.topicToHandlers = {};
    this.unsubscribeMap = {};
    this.currentTimeoutIds = new Set();
    this.__ENABLE_TEST_MODE = Boolean(__ENABLE_TEST_MODE);
    this.messageLog = [];
    this.stateListeners = [];
    this.state = {
      isNavCopilotAvailable: false,
      isWidgetActive: false,
      isWidgetOpen: false,
      layoutType: 'panel'
    };
    this.stateUnsubscribeMap = {};
  }
  removeStateListener(key) {
    const stateListenerToRemove = this.stateUnsubscribeMap[key];
    if (stateListenerToRemove) {
      this.stateListeners = this.stateListeners.filter(cb => cb !== stateListenerToRemove);
    }
    delete this.unsubscribeMap[key];
  }
  generateUniqueUnsubscribeKey() {
    return generateUniqueKeyFor(this.unsubscribeMap);
  }
  unsubscribe(key) {
    const unsubscribeData = this.unsubscribeMap[key];
    if (unsubscribeData) {
      const {
        callback,
        topic
      } = unsubscribeData;
      const currentCallbacks = this.topicToHandlers[topic];
      if (topic && currentCallbacks) {
        const filteredCallbacks = currentCallbacks.filter(cb => cb !== callback);
        if (filteredCallbacks.length) {
          // This is just a filtered list of callbacks, typescript has a hard
          // time here with type narrowing but since we're just filtering out
          // callbacks it's a safe change
          this.topicToHandlers[topic] = filteredCallbacks;
        } else {
          delete this.topicToHandlers[topic];
        }
      }
    }
    delete this.unsubscribeMap[key];
  }
  subscribe(topic, callback) {
    const wrappedCallback = createWrappedCallback(callback);
    const topicHandlers = this.topicToHandlers[topic] || (this.topicToHandlers[topic] = []);
    topicHandlers.push(wrappedCallback);
    const unsubscribeKey = this.generateUniqueUnsubscribeKey();
    this.unsubscribeMap[unsubscribeKey] = {
      topic,
      callback: wrappedCallback
    };
    return () => {
      return this.unsubscribe(unsubscribeKey);
    };
  }
  size() {
    return Object.entries(this.topicToHandlers).filter(([__k, v]) => v && v.length > 0).length;
  }
  cleanup() {
    this.currentTimeoutIds.forEach(timeoutId => clearTimeout(timeoutId));
    this.messageLog = [];
  }
}
export default CopilotChannel;