'use es6';

import Immutable, { fromJS, List as ImmutableList } from 'immutable';
import { exportForLayoutDataApi, importTreeFromLayoutDataApi } from 'layout-data-lib/LayoutDataTree/serialize';
import { BODY_ID, WIDGET_SIDEBAR_NAME_BLOCKLIST, WIDGET_SIDEBAR_TYPE_BLOCKLIST } from 'ContentEditorUI/lib/widgetEdit/constants';
import { createSelector } from 'reselect';
import { basicSelector, basicSelectorWithStats } from 'ContentEditorUI/redux/selectors/helpers';
import { getModuleIdsByBuiltinType, getBuiltinModuleSchemaByType, getBuiltInTypesByModuleId, getGlobalGroupInfo, getGlobalPartialInfo, getGlobalGroupInfoAsImmutable, getGlobalPartialInfoAsImmutable, getSchemaForModule, getAllModuleSchemasByPath } from 'ContentEditorUI/redux/selectors/moduleSchemaSelectors';
import { getSmartViewCriterionId } from 'ContentEditorUI/redux/selectors/smartViewSelectors';
import { getHasGlobalContentEditorAccess, getIsUngatedForFixedLayoutSections } from 'ContentEditorUI/redux/selectors/authSelectors';
import { createCanRedoSelector, createCanUndoSelector, createLastUndoableActionTypeSelector, createLastUndoneActionTypeSelector, createUndoCountSelector, createUndoRedoCountSelector } from 'ContentEditorUI/redux/selectors/undoRedoSelectors';
import I18n from 'I18n';
import { Map as ImmutableMap, List } from 'immutable';
import { findModuleById, getIconForModule, isCmv2Module, isV1GlobalModule, isCmv2GlobalModule, isCustomCmv2Module } from 'ContentEditorUI/data/moduleUtils';
import { getIsPage, getIsBlogPost } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getUndoableModules, getModuleLists, getSchemaLayoutSectionTrees, getContentLayoutSectionTrees, makeCachedStaticModulesSelector, makeCachedModulesInsideAllFlexColumnsSelector, makeCachedModulesInsideLayoutSectionSelector, makeCachedRootModulesForEachLayoutSectionSelector, getLayoutSectionWidgetsModuleMap, getFlexAreasMetadata, getWidgetsInRichText, getEmbedInfo } from './moduleSelectorHelpers';
import { getImmutableOrPlain } from 'ContentEditorUI/utils/dataHelpers';
import EditorConfigSingleton from '../../EditorConfigSingleton';
import { makeHublModuleElementManager } from 'tinymce-plugins/hsmoduleinsertion/utils/hubl';
import { DND_AREA_ID } from 'ContentEditorUI/utils/email/contentTreeUtils';
import { captureMessage } from 'ContentEditorUI/lib/exceptions';
import { getContentState } from './baseContentModelSelectors';
import { forEachTree } from 'layout-dnd-utils/layoutTreeIterators';
export { getModuleLists, getSchemaLayoutSectionTrees, getContentLayoutSectionTrees };
export const getModuleUndoCount = createUndoCountSelector(getUndoableModules);
export const getModuleUndoRedoCount = createUndoRedoCountSelector(getUndoableModules);
export const getCanUndoModules = createCanUndoSelector(getUndoableModules);
export const getCanRedoModules = createCanRedoSelector(getUndoableModules);
export const getLastUndoableActionType = createLastUndoableActionTypeSelector(getUndoableModules);
export const getLastUndoneActionType = createLastUndoneActionTypeSelector(getUndoableModules);
const getContentCss = createSelector(getModuleLists, moduleLists => moduleLists.get('contentCss'));
export const getAllEditableStaticModules = makeCachedStaticModulesSelector();
export const getAllFlexColumnModulesAndContainer = makeCachedModulesInsideAllFlexColumnsSelector();
export const getModulesInsideLayoutSections = makeCachedModulesInsideLayoutSectionSelector();

// Fake body widget used to set body styles
export const getFakeBodyModuleCss = createSelector(getContentCss, contentCss => contentCss.get(BODY_ID) || ImmutableMap());
export const getHasAnyFakeBodyModuleCss = createSelector(getFakeBodyModuleCss, bodyCss => !!bodyCss && bodyCss.size > 0);
export const getFakeBodyModule = createSelector(getFakeBodyModuleCss, bodyCss => {
  return ImmutableMap({
    type: 'fake',
    selector: 'body',
    id: BODY_ID,
    name: BODY_ID,
    label: 'Whole Page (<body>)',
    css: bodyCss
  });
});
const getRootModulesForEachLayoutSection = makeCachedRootModulesForEachLayoutSectionSelector();
const getRootModulesForEachLayoutSectionAsMap = createSelector(getRootModulesForEachLayoutSection, rootModulesForEachLayoutSection => {
  return new ImmutableMap(rootModulesForEachLayoutSection.map(rootModule => [rootModule.get('name'), rootModule]));
});
export const getFakeModules = createSelector([getModuleLists], moduleLists => moduleLists.get('fakeModules'));
export const selectIsFakeModule = (state, id) => getFakeModules(state).has(id);

// Exclude the container wrapper that "surrounds" the whole flex column
export const getAllFlexColumnModules = createSelector(getAllFlexColumnModulesAndContainer, allFlexColumnModulesAndContainer => {
  return allFlexColumnModulesAndContainer.filter(module => module.get('type') !== 'container');
});
export const getAllStaticAndFlexColumnModules = createSelector(getAllEditableStaticModules, getAllFlexColumnModules, (allStaticModules, allFlexColumnModules) => allStaticModules.merge(allFlexColumnModules));
export const isFakeBodyModuleName = name => name === 'post_body';
export const getFakePostBodyModule = createSelector([getFakeModules], fakeModules => fakeModules.find(module => isFakeBodyModuleName(module.get('name'))));
export const getFakePostBodyHTML = createSelector([getFakePostBodyModule], fakePostBodyModule => {
  if (fakePostBodyModule === undefined) {
    return null;
  }
  return fakePostBodyModule.getIn(['body', 'html']);
});
export const getModulesFromPostBody = createSelector(getFakePostBodyModule, getAllModuleSchemasByPath, getWidgetsInRichText, (postBodyModule, allModuleSchemasByPath, widgetsInRichText) => {
  if (!postBodyModule) {
    return ImmutableList();
  }
  const postBodyHtml = postBodyModule.getIn(['body', 'html'], '');
  const hublModuleElementManager = makeHublModuleElementManager(postBodyHtml);
  const postBodyModuleNames = new Set();
  const postBodyModules = hublModuleElementManager.getAll().map(module => {
    const moduleName = module && module.info && module.info.name;
    if (postBodyModuleNames.has(moduleName)) {
      const message = `[ERROR] Duplicate module found in post body`;
      captureMessage(message, {
        extra: {
          moduleName
        }
      });
      console.error(message, moduleName);
    }
    postBodyModuleNames.add(moduleName);
    return widgetsInRichText.get(moduleName);
  }).filter(module => {
    if (!module) {
      const message = "[ERROR] Couldn't find module in widgetInRichText slice";
      captureMessage(message);
      console.error(message);
    }
    return Boolean(module);
  });
  return fromJS(postBodyModules);
});
export const getModulesByNameFromPostBody = createSelector(getModulesFromPostBody, postBodyModulesByName => ImmutableMap(postBodyModulesByName.map(module => [module.get('name'), module])));
export const selectIsPostBodyModule = (state, {
  moduleName
}) => getModulesByNameFromPostBody(state).has(moduleName);
const BLOG_POST_BODY_MODULES = 'blog_post_body_modules';
export const getIsBlogPostBodyModuleGroup = id => id === BLOG_POST_BODY_MODULES;
export const getPostBodyModulesAsLayoutTree = createSelector(getFakePostBodyModule, getModulesFromPostBody, (fakePostBodyModule, postBodyModules) => {
  if (!fakePostBodyModule) {
    return null;
  }
  const treeNodeDefaultValues = {
    styles: {},
    x: 0,
    w: 12
  };
  const makeTreeNodeFromModule = (module, extraData = {}, extraParams = {}) => Object.assign({}, treeNodeDefaultValues, module.toJS(), extraData, {
    params: Object.assign({}, module.get('body').toJS(), extraParams)
  });
  const makeTreeRows = rows => rows.map(({
    module,
    extraData,
    extraParams,
    rows: childRows = []
  }) => ({
    0: Object.assign({}, makeTreeNodeFromModule(module, extraData, extraParams), {
      rows: makeTreeRows(childRows)
    })
  }));
  const moduleRowData = [{
    module: fakePostBodyModule,
    extraData: {
      type: 'cell'
    },
    extraParams: {
      isModuleParent: true
    },
    rows: [...postBodyModules.map(module => ({
      module
    }))]
  }];
  const moduleTreeData = {
    label: I18n.text('widgetList.blogPostContent'),
    name: BLOG_POST_BODY_MODULES,
    id: BLOG_POST_BODY_MODULES,
    rows: makeTreeRows(moduleRowData),
    rowMetaData: [{
      skipNode: true
    }]
  };
  return importTreeFromLayoutDataApi(moduleTreeData);
});
export const getModules = createSelector(getAllEditableStaticModules, getAllFlexColumnModulesAndContainer, getModulesInsideLayoutSections, getRootModulesForEachLayoutSectionAsMap, getFakeModules, getFakeBodyModule, getModulesByNameFromPostBody, (allStaticModules, allFlexColumnModulesAndContainer, modulesInsideLayoutSections, rootModulesForEachLayoutSectionAsMap, fakeModules, fakeBodyModule, postBodyModules) => {
  const modules = allStaticModules.merge(modulesInsideLayoutSections).merge(rootModulesForEachLayoutSectionAsMap).merge(allFlexColumnModulesAndContainer).merge(fakeModules).merge(postBodyModules).set(BODY_ID, fakeBodyModule);
  return modules;
});
export const getModuleMetaData = createSelector([getModuleLists], moduleLists => moduleLists.get('moduleMetaData'));
export const getUneditableModules = createSelector([getModuleLists], moduleLists => moduleLists.get('uneditableWidgets'));
export const getAllModuleKindsHelper = createSelector(getModules, getUneditableModules, getFakeModules, (modules, uneditableModules, fakeModules) => ({
  modules,
  uneditableModules,
  fakeModules
}));
const isFakePostTitleName = name => name === 'name';
export const getFakePostTitleModule = createSelector([getFakeModules], fakeModules => fakeModules.find(module => isFakePostTitleName(module.get('name'))));
export const getHasAnyFlexColumns = createSelector(getModuleLists, moduleLists => moduleLists.get('schemaWidgetContainers').size > 0);
export const getHasModulesInFlexColumn = basicSelectorWithStats(state => {
  const moduleLists = getModuleLists(state);
  return moduleLists.get('widgetContainers').filter(container => {
    return container.size > 0;
  }).size > 0;
});
export const getIsModuleOverrideable = basicSelectorWithStats((state, id) => {
  const module = getModules(state).get(id);
  if (module) {
    return module.get('overrideable');
  }
  return false;
});
export const getAllNonFakeBodyModules = createSelector(getModules, modules => modules.delete(BODY_ID));
export const getModuleById = basicSelectorWithStats((state, id) => {
  const modules = getModules(state);
  return findModuleById(modules, id);
});
export const getRichTextModuleById = basicSelectorWithStats((state, id) => {
  const modules = getWidgetsInRichText(state);
  return findModuleById(modules, id);
});
export const getIsModuleInLayoutSection = basicSelectorWithStats((state, id) => {
  const module = getModuleById(state, id);
  return module && module.has('layout_section_id');
});
export const getIsLayoutSectionModuleIsIn = basicSelectorWithStats((state, id) => {
  const module = getModuleById(state, id);
  return module && module.get('layout_section_id');
});
export const getModuleOrUneditableOrFakeModuleByIdHelper = (allModuleKinds, id) => {
  const {
    modules,
    uneditableModules,
    fakeModules
  } = allModuleKinds;
  return findModuleById(modules, id) || uneditableModules.get(id) || fakeModules.get(id);
};
export const getAllModulesSmartRulesDefinitionIds = createSelector(getModules, getUneditableModules, (modules, uneditableModules) => {
  const definitionIds = [];
  modules.forEach(module => {
    const definitionId = module.get('definition_id');
    if (definitionId) {
      definitionIds.push(definitionId);
    }
  });
  uneditableModules.forEach(module => {
    const definitionId = module.get('definition_id');
    if (definitionId) {
      definitionIds.push(definitionId);
    }
  });
  return definitionIds;
});
export const getModuleMetaDataById = basicSelectorWithStats((state, id) => {
  const moduleMetaData = getModuleMetaData(state);
  return moduleMetaData.get(id);
});
export const getUneditableModuleById = basicSelectorWithStats((state, id) => {
  const uneditableModules = getUneditableModules(state);
  return uneditableModules.get(id);
});
export const getGlobalGroupByPath = basicSelectorWithStats((state, path) => {
  const globalGroups = getGlobalGroupInfo(state);
  return globalGroups[path];
});
export const getGlobalGroupByPathAsImmutable = basicSelector((state, path) => {
  const globalGroups = getGlobalGroupInfoAsImmutable(state);
  return globalGroups && globalGroups.get(path);
});
export const getGlobalPartialByPath = basicSelectorWithStats((state, path) => {
  const globalPartials = getGlobalPartialInfo(state);
  return globalPartials[path];
});
export const getGlobalPartialByPathAsImmutable = basicSelector((state, path) => {
  const globalPartials = getGlobalPartialInfoAsImmutable(state);
  return globalPartials && globalPartials.get(path);
});
export const getGlobalGroupOrPartialByPath = basicSelectorWithStats((state, path) => {
  return getGlobalGroupByPath(state, path) || getGlobalPartialByPath(state, path);
});
export const getGlobalGroupOrPartialByPathAsImmutable = basicSelectorWithStats((state, path) => {
  return getGlobalGroupByPathAsImmutable(state, path) || getGlobalPartialByPathAsImmutable(state, path);
});
export const getFakeModuleById = basicSelectorWithStats((state, id) => {
  const fakeModules = getFakeModules(state);
  return fakeModules.get(id);
});
export const getModuleOrFakeModuleById = basicSelectorWithStats((state, id) => {
  return getModuleById(state, id) || getFakeModuleById(state, id);
});
export const getModuleOrUneditableModuleById = basicSelectorWithStats((state, id) => {
  return getModuleById(state, id) || getUneditableModuleById(state, id);
});
export const getAnyModuleById = basicSelectorWithStats((state, id) => {
  return getModuleById(state, id) || getFakeModuleById(state, id) || getUneditableModuleById(state, id) || getGlobalGroupByPathAsImmutable(state, id) || getGlobalPartialByPathAsImmutable(state, id);
});
export const makeGetAnyModuleById = moduleId => createSelector(getModules, getFakeModules, getUneditableModules, (modules, fakeModules, uneditableModules) => findModuleById(modules, moduleId) || fakeModules.get(moduleId) || uneditableModules.get(moduleId));
export const getIsCMv2Module = basicSelectorWithStats((state, id) => {
  const module = getAnyModuleById(state, id);
  if (module) {
    const schema = getSchemaForModule(state, module);
    return isCmv2Module(module, schema);
  }
  return false;
});

// Intentionally choosing this to be one of the few situations we support passing in
// Immutable or plain JS objects
export const getDefaultTypeHelper = (module, schema, builtInTypesByModuleId) => {
  if (schema && getImmutableOrPlain(schema, 'default')) {
    const moduleId = getImmutableOrPlain(module, 'module_id');
    return builtInTypesByModuleId[moduleId] || getImmutableOrPlain(module, 'type');
  }
  return getImmutableOrPlain(module, 'type');
};
export const getTypeByModuleId = (moduleId, builtInTypesByModuleId) => {
  return builtInTypesByModuleId[moduleId] || 'custom';
};
export const getDefaultType = basicSelectorWithStats((state, id) => {
  const module = getModuleOrUneditableModuleById(state, id);
  const builtInTypesByModuleId = getBuiltInTypesByModuleId(state);
  if (module) {
    const schema = getSchemaForModule(state, module);
    return getDefaultTypeHelper(module, schema, builtInTypesByModuleId);
  }
  return null;
});
export const getDisplayName = basicSelectorWithStats((state, idOrPath) => {
  const module = getAnyModuleById(state, idOrPath);
  if (module) {
    const type = getDefaultType(state, idOrPath);
    const schema = getSchemaForModule(state, module);
    let moduleLabel = module.get('label');
    if (moduleLabel && schema && schema.originalDisplayName && moduleLabel === schema.originalDisplayName) {
      moduleLabel = schema.displayName;
    }
    if (getIsCMv2Module(state, idOrPath) && schema && !schema.global) {
      return moduleLabel || schema.displayName;
    } else if (schema && (type === 'module' || type === 'custom_widget' || type === 'global_widget' || type === 'js_module')) {
      return schema.displayName;
    }
    return moduleLabel || I18n.text(`contentmodules.widgets.${module.get('type')}.label`);
  }
  return null;
});
export const getIsDefaultModule = basicSelectorWithStats((state, id) => {
  const module = getModuleById(state, id);
  if (module) {
    const schema = getSchemaForModule(state, module);
    if (schema) {
      return schema.default;
    }
  }
  return false;
});
const moduleHasAnyStylesHelper = module => {
  const css = !!module && module.get('css');
  return css && css instanceof Immutable.Map && module.get('css').size > 0;
};
export const getHasAnyRegularNonLayoutModuleStyles = basicSelectorWithStats((state, id) => {
  const module = getModuleById(state, id);
  return moduleHasAnyStylesHelper(module);
});
export const getAllV2ModuleDefinitionIdsWithStylesOnPage = createSelector(getModules, allModules => {
  return new Immutable.Set(allModules.valueSeq().map(module => {
    if (moduleHasAnyStylesHelper(module)) {
      return module.get('module_id');
    }
    return undefined;
  })
  // Filter out undefined
  .filter(x => !!x));
});
export const getIsGlobalModule = basicSelectorWithStats((state, id) => {
  const module = getModuleById(state, id);
  const moduleSpec = !!module && getSchemaForModule(state, module);
  return isV1GlobalModule(module) || moduleSpec && isCmv2GlobalModule(module, moduleSpec);
});
export const getGlobalModuleNames = basicSelectorWithStats(state => {
  const modules = getModules(state);
  const globalModuleNames = [];
  modules.forEach(module => {
    const moduleName = module.get('name');
    if (getIsGlobalModule(state, moduleName)) {
      globalModuleNames.push(moduleName);
    }
  });
  return globalModuleNames;
});
export const getIsGlobalModuleGroupOrPartial = basicSelectorWithStats((state, idOrPath) => {
  const isGlobalModule = getIsGlobalModule(state, idOrPath);
  const isGroupOrPartial = !!getGlobalGroupOrPartialByPath(state, idOrPath);
  return isGlobalModule || isGroupOrPartial;
});
export const getIsEditableGlobalContent = basicSelectorWithStats((state, idOrPath) => {
  const module = getModuleById(state, idOrPath);
  const hasGlobalContentEditor = getHasGlobalContentEditorAccess(state);
  if (module) {
    const isGlobalModule = getIsGlobalModule(state, idOrPath);
    return hasGlobalContentEditor && isGlobalModule;
  }
  const globalGroupOrPartial = getGlobalGroupOrPartialByPath(state, idOrPath);
  if (globalGroupOrPartial) {
    return hasGlobalContentEditor;
  }
  return false;
});
export const getIsFakeBodyOrTitleModule = basicSelectorWithStats((state, id) => {
  const module = getFakeModuleById(state, id);
  if (module) {
    const name = module.get('name');
    return isFakeBodyModuleName(name) || isFakePostTitleName(name);
  }
  return false;
});
export const getIsModuleEditable = basicSelectorWithStats((state, id) => getIsFakeBodyOrTitleModule(state, id) || getIsModuleOverrideable(state, id) && !getIsGlobalModuleGroupOrPartial(state, id) || getIsEditableGlobalContent(state, id));
export const getIsCustomCmv2Module = basicSelectorWithStats((state, id) => {
  const module = getModuleById(state, id);
  if (module) {
    const schema = getSchemaForModule(state, module);
    return isCustomCmv2Module(schema);
  }
  return false;
});
export const getIsEmailBodyModule = basicSelectorWithStats((state, id) => {
  if (getIsCMv2Module(state, id)) {
    const module = getModuleById(state, id);
    const builtInTypesByModuleId = getBuiltInTypesByModuleId(state);
    return builtInTypesByModuleId[module.get('module_id')] === 'email_body';
  }
  const contentState = getContentState(state);
  return !(contentState.includes('BLOG') || contentState.includes('RSS')) && id === 'hs_email_body';
});
export const getIsEmailViewAsWebPage = basicSelectorWithStats((state, id) => {
  if (getIsCMv2Module(state, id)) {
    const module = getModuleById(state, id);
    const builtInTypesByModuleId = getBuiltInTypesByModuleId(state);
    return builtInTypesByModuleId[module.get('module_id')] === 'email_view_as_web_page';
  }
  return id === 'email_view_as_web_page';
});
export const getIsEmailFooterModule = basicSelectorWithStats((state, id) => {
  if (getIsCMv2Module(state, id)) {
    const module = getModuleById(state, id);
    const builtInTypesByModuleId = getBuiltInTypesByModuleId(state);
    return builtInTypesByModuleId[module.get('module_id')] === 'email_can_spam';
  }
  return id === 'email_footer';
});
export const getContainers = createSelector(getModules, modules => modules.filter(module => module.get('type') === 'container'));
export const getHasContainers = createSelector(getContainers, containers => containers && containers.count() > 0);
export const getModulesInContainers = createSelector(getModules, modules => modules.filter(module => module.has('containerId') && !module.has('layout_section_id')));
export const getHiddenModules = createSelector(getModules, modules => modules.filter(module => module.get('export_to_template_context') === true).sortBy(module => module.get('order')));
export const getUneditableModulesVisibleInSidebar = createSelector([getUneditableModules, getModuleIdsByBuiltinType], (uneditableModules, moduleIdsByBuiltinType) => {
  const blogContentModuleId = moduleIdsByBuiltinType.blog_content;
  return uneditableModules.filter(module => module.get('module_id') !== blogContentModuleId);
});
const getGlobalGroupsForSidebar = createSelector([getUneditableModules,
// Since the sidebar uses modules and global references interchangeably, make sure to get
// global info as an immutable Map
getGlobalGroupInfoAsImmutable, getGlobalPartialInfoAsImmutable], (uneditableModules, globalGroupInfoMap, globalPartialInfoMap) => {
  let groupInfo = globalGroupInfoMap.concat(globalPartialInfoMap);
  if (groupInfo) {
    uneditableModules.forEach(module => {
      const isPartial = module.has('global_partial_path');
      if (module.has('global_group_path') || isPartial) {
        const type = isPartial ? 'global_partial' : 'global_group';
        const path = `${module.get(`${type}_path`)}`;
        if (groupInfo.has(path)) {
          const order = groupInfo.get('order');
          if (!order || module.get('order') < order) {
            groupInfo = groupInfo.mergeIn([path], Immutable.Map({
              line_number: module.get('line_number'),
              order: module.get('order'),
              path,
              type
            }));
          }
        }
      }
    });
    return groupInfo;
  }
  return Immutable.Map();
});
const getModulesVisibleInSidebar = createSelector([getModules, getUneditableModulesVisibleInSidebar, getGlobalGroupsForSidebar], (modules, uneditableModulesVisibleInSidebar, globalGroupsForSidebar) => {
  return modules.filter(module => WIDGET_SIDEBAR_TYPE_BLOCKLIST.indexOf(module.getIn(['type'])) === -1 && WIDGET_SIDEBAR_NAME_BLOCKLIST.indexOf(module.getIn(['name'])) === -1).concat(uneditableModulesVisibleInSidebar).concat(globalGroupsForSidebar);
});
export const getSortedStaticModulesAndContainers = createSelector([getModulesVisibleInSidebar, getModulesByNameFromPostBody], (modulesVisibleInSidebar, postBodyModules) => {
  return modulesVisibleInSidebar.filter(module => module.has('line_number') && !module.has('containerId') && !module.has('layout_section_id') && !module.has('global_group_path') && !module.has('global_partial_path') && module.get('export_to_template_context') !== true && !postBodyModules.has(module.get('name')) && module.get('path') !== '@hubspot/blog_audio').sortBy(module => module.get('order'));
});
export const getModulesInContainerHelper = (modules, containerId) => {
  return modules.filter(module => module.get('containerId') === containerId).sortBy(module => module.get('order'));
};
export const getModulesInLayoutSectionHelper = (modules, layoutSectionTree) => {
  return List(layoutSectionTree.allModules().map(cell => modules.get(cell.getName())));
};
export const getModulesInAnyLayoutSection = createSelector(getModules, modules => {
  return modules.filter(module => module.has('layout_section_id'));
});

// All global v1 modules not in flex columns or D&D areas
const getAllStaticGlobalV1Modules = createSelector(getUneditableModules, uneditableModules => {
  return uneditableModules.filter(isV1GlobalModule);
});
const getLayoutSectionTrees = createSelector(getModuleLists, moduleLists => moduleLists.get('layoutSectionTrees'));
export const getAllStaticSectionsFromTrees = createSelector([getLayoutSectionTrees], layoutSectionTrees => {
  let staticSection = [];
  layoutSectionTrees.forEach(tree => {
    const currentTreesStaticSections = tree
    // need to go deeper than the root, as a static section is top level
    .getRootCell().getRows().filter(row => row.isStaticSection());
    staticSection = staticSection.concat(currentTreesStaticSections);
  });
  return staticSection;
});
const getStaticSectionModules = createSelector([getLayoutSectionWidgetsModuleMap, getAllStaticSectionsFromTrees], (layoutSectionWidgetsModuleMap, allStaticSections) => {
  let allStaticSectionModules = ImmutableMap();
  allStaticSections.forEach(currentStaticSection => {
    const moduleChildren = currentStaticSection.getModuleChildren();
    moduleChildren.forEach(module => {
      const name = module.getName();
      const moduleFromMap = layoutSectionWidgetsModuleMap.get(name);
      // This is to prevent breakage in that I currently don't correctly update the
      // layoutSectionWidgetsModuleMap
      // Once that is addressed, the if can be removed
      if (moduleFromMap) {
        allStaticSectionModules = allStaticSectionModules.set(name, moduleFromMap);
      }
    });
  });
  return allStaticSectionModules;
});

// Gather all static modules, those "editable" and not (like v1 globals not in any container)
const getAllStaticModules = createSelector(getAllEditableStaticModules, getUneditableModules, getAllStaticGlobalV1Modules, getFakeModules, getStaticSectionModules, getIsUngatedForFixedLayoutSections, (allEditableStaticModules, uneditableModules, allStaticGlobalV1Modules, fakeModules, staticSectionModules, isUngatedForFixedLayoutSections) => {
  let modules = allEditableStaticModules.merge(uneditableModules).merge(allStaticGlobalV1Modules).merge(fakeModules);

  // TODO: Clean this up when removing `CMS:FixedLayoutSections` gate.
  if (isUngatedForFixedLayoutSections) {
    modules = modules.merge(staticSectionModules);
  }
  return modules;
});
const MODULES_TO_NOT_SHOW_OVERLAYS_FOR = new Set();
MODULES_TO_NOT_SHOW_OVERLAYS_FOR.add('@hubspot/blog_content');
export const getAllStaticModulesToShowOverlaysOn = createSelector(getAllStaticModules, allStaticModules => allStaticModules.filter(module => {
  const isInPathFilterList = MODULES_TO_NOT_SHOW_OVERLAYS_FOR.has(module.get('path'));
  const isInsideGlobalPartialOrGroup = !!module.get('global_partial') || !!module.get('global_group_path');
  return !isInPathFilterList && !isInsideGlobalPartialOrGroup;
}));
const filterModulesWithSmartContent = modules => modules.filter(module => {
  const smartType = module.get('smart_type');
  const smartObjects = module.get('smart_objects');
  return smartType && smartObjects && !smartObjects.isEmpty();
});
export const getModulesWithSmartContent = createSelector([getModules], filterModulesWithSmartContent);
export const getUneditableModulesWithSmartContent = createSelector([getUneditableModules], filterModulesWithSmartContent);
export const getAnyModulesHaveSmartContent = createSelector([getModulesWithSmartContent, getUneditableModulesWithSmartContent], (modulesWithSmartContent, uneditableModulesWithSmartContent) => modulesWithSmartContent.size + uneditableModulesWithSmartContent.size > 0);

// returns the index of the smart object if the id matches the smart view criterion id
export const getModuleMatchingSmartObjectIndex = basicSelectorWithStats((state, id) => {
  const module = getModuleById(state, id);
  const smartViewCriterionId = getSmartViewCriterionId(state);
  let matchingSmartObjectIndex = -1;
  if (module && module.has('smart_objects') && module.get('smart_objects') !== null && smartViewCriterionId) {
    matchingSmartObjectIndex = module.get('smart_objects').findIndex(smartObject => smartObject.get('criterion_id') === smartViewCriterionId);
  }
  return matchingSmartObjectIndex;
});
export const getModuleMatchingSmartCriteriaIds = basicSelectorWithStats((state, id) => {
  const module = getModuleById(state, id);
  let criteriaIds = [];
  if (module && module.has('smart_objects') && module.get('smart_objects') !== null) {
    criteriaIds = module.get('smart_objects').map(smartObject => smartObject.get('criterion_id')).toJS();
  }
  return criteriaIds;
});
export const getSchemaLayoutSectionsAsJson = createSelector(getModuleLists, moduleLists => moduleLists.get('schemaLayoutSectionTrees').map(tree => {
  return exportForLayoutDataApi(tree);
}).toJS());
export const getLayoutSectionsAsJson = createSelector(getLayoutSectionTrees, layoutSectionTrees => layoutSectionTrees.map(tree => {
  return exportForLayoutDataApi(tree);
}).toJS());
export const getAllLayoutSectionIds = createSelector([getSchemaLayoutSectionTrees], schemaLayoutSectionTrees => ImmutableMap.isMap(schemaLayoutSectionTrees) ? List(schemaLayoutSectionTrees.keys()) : List());
export const getAllLayoutSectionTreesAsMap = createSelector(getModuleLists, getAllLayoutSectionIds, (moduleLists, layoutSectionIds) => {
  return layoutSectionIds.reduce((result, layoutSectionId) => {
    const contentTree = moduleLists.getIn(['layoutSectionTrees', layoutSectionId]);
    const schemaTree = moduleLists.getIn(['schemaLayoutSectionTrees', layoutSectionId]);
    return result.set(layoutSectionId, contentTree || schemaTree);
  }, new ImmutableMap());
});
export const getFlexAreaTreeAsMap = createSelector(getModuleLists, moduleLists => moduleLists.get('flexAreaTree'));
export const getHasAnyLayoutSections = createSelector([getAllLayoutSectionIds], allLayoutSectionIds => !!allLayoutSectionIds.size);
export const getHasRichTextModuleThatSupportsModuleInsertion = createSelector([getFakePostBodyModule], fakePostBodyModule => Boolean(fakePostBodyModule));
export const getHasAnyContainerOrDndAreaToAddModules = createSelector([getHasContainers, getHasAnyLayoutSections, getHasRichTextModuleThatSupportsModuleInsertion], (hasAnyContainers, hasAnyLayoutSections, hasRichTextModuleThatSupportsModuleInsertion) => {
  return hasAnyContainers || hasAnyLayoutSections || hasRichTextModuleThatSupportsModuleInsertion;
});
export const getContentLayoutSectionTreeById = basicSelectorWithStats((state, layoutSectionId) => {
  return getContentLayoutSectionTrees(state).get(layoutSectionId);
});
export const getSchemaLayoutSectionTreeById = basicSelectorWithStats((state, layoutSectionId) => {
  return getSchemaLayoutSectionTrees(state).get(layoutSectionId);
});
export const getLayoutSectionTreeById = basicSelectorWithStats((state, {
  layoutSectionId
}) => {
  const contentTree = getContentLayoutSectionTreeById(state, layoutSectionId);
  const schemaTree = getSchemaLayoutSectionTreeById(state, layoutSectionId);
  return contentTree || schemaTree;
});
export const getLayoutSectionIsEmpty = basicSelectorWithStats((state, layoutSectionId) => {
  return getLayoutSectionTreeById(state, {
    layoutSectionId
  }).isEmpty();
});
export const getCellOrRowByIdHelper = (tree, id) => {
  if (tree && tree.hasRow(id)) {
    return tree.findRow(id);
  } else if (tree && tree.hasCell(id)) {
    return tree.findCell(id);
  }
  return null;
};
export const getCellOrRowById = basicSelectorWithStats((state, {
  id,
  layoutSectionId
}) => {
  const tree = getLayoutSectionTreeById(state, {
    layoutSectionId
  });
  return getCellOrRowByIdHelper(tree, id);
});
export const makeGetCellOrRowById = () => createSelector(getCellOrRowById, node => node);
export const makeGetCellOrRowParentId = () => createSelector(getCellOrRowById, cell => {
  if (cell) {
    return cell.getParentName();
  }
  return null;
});
export const makeGetIsOnlyChildInParent = () => createSelector(getCellOrRowById, cell => {
  const parent = cell.getParent();
  if (parent.isRow()) {
    return parent.getNumberColumns() === 1;
  } else if (parent.isCell()) {
    return parent.getNumberRows() === 1;
  }
  return null;
});
export const makeGetCanCellBeCloned = () => createSelector(getCellOrRowById, cell => {
  if (!cell || !cell.isCell()) {
    return undefined;
  }

  // If cloning a single module in a column, clone the parent column instead
  const cellToClone = cell.isOnlyModuleInWrapperColumn() ? cell.getParent().getParent() : cell;
  return cellToClone.getParent().hasRoomForMoreColumns();
});
const getSchemaForBuiltinModuleType = basicSelectorWithStats((state, moduleType) => {
  if (moduleType !== 'module') {
    return getBuiltinModuleSchemaByType(state)[moduleType];
  }
  return null;
});
export const getModuleIcon = basicSelectorWithStats((state, moduleId) => {
  const module = getModuleById(state, moduleId);
  if (module) {
    const schemaFromBuiltInMapping = getSchemaForBuiltinModuleType(state, module.get('type'));
    const schema = getSchemaForModule(state, module);
    return getIconForModule(module, schema, schemaFromBuiltInMapping);
  }
  return null;
});
export const getModuleGroups = createSelector([getHiddenModules, getSortedStaticModulesAndContainers, getAllFlexColumnModulesAndContainer, getAllLayoutSectionTreesAsMap, getPostBodyModulesAsLayoutTree], (hiddenModules, sortedStaticModulesAndContainer, allFlexColumnModulesAndContainer, allLayoutSectionTreesAsMap, postBodyModulesAsTree) => {
  const moduleGroups = {};
  if (hiddenModules.size > 0) {
    moduleGroups.hiddenModules = {
      index: -2,
      type: 'module',
      label: I18n.text('widgetList.hiddenModulesHeader'),
      modules: hiddenModules
    };
  }
  if (postBodyModulesAsTree) {
    const rootCell = postBodyModulesAsTree.getRootCell();
    moduleGroups[BLOG_POST_BODY_MODULES] = {
      index: -1,
      type: 'layout_section',
      label: rootCell.getValue().label,
      name: BLOG_POST_BODY_MODULES,
      modules: rootCell
    };
  }
  sortedStaticModulesAndContainer.entrySeq().forEach(([id, body], index, sortedStaticModulesAndContainerArray) => {
    const type = body.get('type');
    const label = body.get('label');
    const name = body.get('name');
    if (type === 'container') {
      moduleGroups[id] = {
        index,
        type,
        label: label || name,
        modules: getModulesInContainerHelper(allFlexColumnModulesAndContainer, id)
      };
    } else if (type === 'layout_section') {
      moduleGroups[id] = {
        index,
        name,
        type,
        label,
        modules: allLayoutSectionTreesAsMap.get(name).getRootCell()
      };
    } else if (type === 'module') {
      if (index > 0) {
        const prevEntry = sortedStaticModulesAndContainerArray.get(index - 1);
        const [__prevId, prevBody] = prevEntry;
        // if the last item is a module, we used it to get all its neighbor modules
        if (prevBody.get('type') === 'module') return null;
      }
      // only look at entries from this point forward
      const sortedStaticModulesAndContainersEntriesSubset = sortedStaticModulesAndContainerArray.skip(index);
      let staticModules = List([]);
      sortedStaticModulesAndContainersEntriesSubset.forEach(([__itemId, itemBody]) => {
        // iteration stops when false is returned https://immutable-js.com/docs/v4.0.0/List/#forEach()
        if (itemBody.get('type') !== 'module') return false;
        staticModules = staticModules.push(itemBody);
        return staticModules;
      });
      moduleGroups[id] = {
        index,
        type,
        label: I18n.text('widgetList.staticModulesHeader'),
        modules: staticModules
      };
    } else {
      moduleGroups[id] = {
        index,
        type,
        label,
        modules: Immutable.List([body])
      };
    }
    return null;
  });
  return Immutable.Map(moduleGroups).sortBy(value => value.index);
});
export const getClonedModulesPendingDomOperations = createSelector(getModuleLists, moduleLists => moduleLists.get('clonedModulesPendingDomOperations'));
export const getMapOfClonedToOldStaticSectionName = createSelector(getModuleLists, moduleLists => moduleLists.get('mapOfClonedToOldStaticSectionName'));

// TODO making content type checks in CEUI is a pattern we try to avoid!
// Doing it here because components in IPEUI use this selector, and we do
// not want to roll out undo/redo to email yet.
// TODO: Fix this for scalable editor
export const getHasUndoRedoAccess = createSelector([getIsPage, getIsBlogPost], (isPage, isBlog) => isPage || isBlog || EditorConfigSingleton.getIsOnScalableEditor() && EditorConfigSingleton.getFeatureFlagOrDefault('undoRedo'));
export const getPostBodyCustomModuleParentIds = basicSelectorWithStats(state => {
  const module = getModuleById(state, 'post_body');
  if (module && module.get('isInCustomModule')) {
    return module.get('customModuleParentIds');
  }
  return null;
});
export const getFlexAreaIndex = createSelector(getFlexAreasMetadata, metadata => metadata.get(DND_AREA_ID));
export const getEmbedInfoAsJsObject = createSelector([getEmbedInfo], embedInfo => embedInfo.toJS());
const EMBED_LIMIT = 3;
export const getHasReachedEmbedLimit = createSelector([getAllLayoutSectionTreesAsMap], layoutTrees => {
  let totalEmbeds = 0;
  forEachTree(layoutTrees, tree => {
    const root = tree.getRootCell();
    root.getRows().forEach(row => {
      if (row.isEmbed()) {
        totalEmbeds++;
      }
    });
  });
  return totalEmbeds >= EMBED_LIMIT;
});
export const getAllNormalizedFormModulesInsideLayout = createSelector([getModulesInsideLayoutSections], modules => {
  return modules.filter(module => module.getIn(['body', 'path']) === '@hubspot/form' &&
  // Only consider providing XRay recommendations for valid forms.
  // Invalid forms, forms without a form_id, can be generated from a
  // page template. Follow up emails and automations can only be linked
  // to forms that are saved assets, which is indicated by an existing
  // form_id value
  module.getIn(['body', 'form', 'form_id'])).toList().toJS().map(module => ({
    formModuleId: module.id,
    followUpEmailId: module.body.form.followup_email_id
  }));
});