import styled from 'styled-components';
import SkeletonBase from './SkeletonBase';
import { BOX_BASE_HEIGHT, BOX_BASE_WIDTH, getSize } from './skeletonBaseUtils';
// Default props
const DEFAULT_HEIGHT = BOX_BASE_HEIGHT;
const DEFAULT_WIDTH = BOX_BASE_WIDTH;
const SkeletonBox = styled(SkeletonBase).withConfig({
  displayName: "SkeletonBox",
  componentId: "sc-19o94jx-0"
})(["width:", ";height:", ";min-width:", ";"], ({
  width = DEFAULT_WIDTH
}) => getSize(width), ({
  height = DEFAULT_HEIGHT
}) => getSize(height), ({
  width = DEFAULT_WIDTH
}) => getSize(width));
SkeletonBox.displayName = 'SkeletonBox';
export default SkeletonBox;