import PropTypes from 'prop-types';
import styled from 'styled-components';
const UITourPulserLayer = styled.svg.attrs(props => ({
  // This is for preventing generating duplicate style in renderings
  style: {
    zIndex: props.zIndex
  }
})).withConfig({
  displayName: "UITourPulserLayer",
  componentId: "gd9u63-0"
})(["height:100vh;left:0;opacity:0;overflow:hidden;pointer-events:none;position:fixed;top:0;width:100vw;&.tour-pulser--visible{opacity:1;transition:opacity 0.3s ease-out;}.tour-pulser-overlay{pointer-events:all;}"]);
UITourPulserLayer.propTypes = {
  zIndex: PropTypes.number.isRequired
};
export default UITourPulserLayer;