import { Component } from 'react';
import Raven from 'raven-js';
import { HAS_UNRECOVERABLE_ERROR } from '../../constants/CollaborationSidebarStateProperties';
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError() {
    return {
      hasError: true
    };
  }
  componentDidCatch(error, errorInfo) {
    const {
      onUpdateCollaborationSidebarState
    } = this.props;
    Raven.captureException(new Error(this.props.fingerprint), {
      extra: {
        errorInfo,
        error
      },
      fingerprint: [this.props.fingerprint],
      tags: {
        lib: 'collaboration-sidebar'
      }
    });
    onUpdateCollaborationSidebarState({
      [HAS_UNRECOVERABLE_ERROR]: true
    });
  }
  render() {
    if (this.state.hasError) {
      return this.props.renderFallback();
    }
    return this.props.children;
  }
}