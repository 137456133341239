'use es6';

import Immutable from 'immutable';
import parseModuleId from 'ContentEditorUI/utils/parseModuleId';
import { omit, pick } from 'underscore';
import { ATTRIBUTES_NOT_NEEDED_INSIDE_PARAMS, NON_BODY_ATTRIBUTES_TO_PICK_FROM_CONTENT_WIDGET } from 'ContentEditorUI/data/moduleTransformHelpers';
import { pathOfAllUnnecessaryWrappersUpToDescendentToKeep } from 'layout-data-lib/CellAndRowsTree/helpers';
import { isLinkedImageModuleId } from 'ContentEditorUI/data/moduleUtils';
import { EditorMetricsTracker } from 'ContentEditorUI/utils/metricsTracker';
import { importTreeFromLayoutDataApi } from 'layout-data-lib/LayoutDataTree/serialize';
import CustomCellTweakingAutoDeleteLogicPerTestFlag from 'ContentEditorUI/data/tree/CustomCellTweakingAutoDeleteLogicPerTestFlag';
import { DND_AREA_ID, parseFlexAreaDataForLayoutTree } from 'ContentEditorUI/utils/email/contentTreeUtils';
import set from 'hs-lodash/set';
let isModuleReducerDiffingEnabled = false;
export function enableModuleReducerDiffing() {
  isModuleReducerDiffingEnabled = true;
}
export function getIsModuleReducerDiffingEnabled() {
  return isModuleReducerDiffingEnabled;
}
export const SHARED_ACTION_DATA = {};

// WIDGET CONTAINERS
const getWidgetContainerInfo = (state, id) => {
  let info = {};
  id = parseModuleId(id);
  state.get('widgetContainers').forEach((widgetContainer, widgetContainerKey) => {
    const entry = widgetContainer.get('widgets').findEntry(widget => parseModuleId(widget.get('id')) === id && !widget.get('deleted_at'));
    if (entry) {
      const [order] = entry;
      info = {
        widgetContainerKey,
        order
      };
      return false;
    }
    return true;
  });
  return info;
};
export const getKeyForNonLayoutSection = (state, id) => {
  if (state.hasIn(['widgetsInRichText', id])) {
    return ['widgetsInRichText', id];
  }
  if (state.hasIn(['schemaWidgets', id])) {
    return ['widgets', id];
  }
  const {
    widgetContainerKey,
    order
  } = getWidgetContainerInfo(state, id);
  return ['widgetContainers', widgetContainerKey, 'widgets', order];
};
const resetOrdersInContainer = (state, containerKey) => {
  const widgetListKey = ['widgetContainers', containerKey, 'widgets'];
  return state.getIn(widgetListKey).reduce((currentState, module, i) => {
    if (i !== module.get('order')) {
      currentState = currentState.setIn([...widgetListKey, i, 'order'], i);
    }
    return currentState;
  }, state);
};
export const addModuleToFlexColumnHelper = (state, columnId, index, module) => {
  let widgetContainer = state.getIn(['widgetContainers', columnId]);
  if (!widgetContainer) {
    widgetContainer = state.getIn(['schemaWidgetContainers', columnId]);
    state = state.setIn(['widgetContainers', columnId], widgetContainer);
    if (!widgetContainer) {
      throw new Error(`Cannot find widget container with given id: ${columnId}`);
    }
  }
  let widgetContainerWidgets = widgetContainer.has('widgets') ? widgetContainer.get('widgets') : new Immutable.List();
  const insertIndex = isNaN(index) ? widgetContainerWidgets.size : index;
  widgetContainerWidgets = widgetContainerWidgets.insert(insertIndex, module);
  state = state.setIn(['widgetContainers', columnId, 'widgets'], widgetContainerWidgets);
  return resetOrdersInContainer(state, columnId);
};
export const removeModule = (state, id) => {
  const {
    widgetContainerKey
  } = getWidgetContainerInfo(state, id);
  state = state.deleteIn(getKeyForNonLayoutSection(state, id));
  return resetOrdersInContainer(state, widgetContainerKey);
};

// LAYOUT SECTIONS
const prepareNewlyClonedContentTree = (tree, layoutSectionWidgets, {
  isUngatedForMergeInDefaultValues
}) => {
  const mapOfLayoutSectionWidgetsForTree = new Immutable.Map(layoutSectionWidgets.get(tree.getRootName(), new Immutable.List()).map(w => [w.get('name'), w.toJS()]));

  // Step 1) Bring along previous edits that live outside the D&D area (more info in
  // https://git.hubteam.com/HubSpot/ContentEditorUI/pull/4217)
  tree.allModules().map(cell => cell.getName()).forEach(cellName => {
    const layoutSectionWidget = mapOfLayoutSectionWidgetsForTree.get(cellName);

    // If it exists, merge the (cleaned up) body from the layout section widget with the other.
    // non-body attributes (and sigh, more params <-> body pain that we need to someday deal with)
    if (layoutSectionWidget) {
      const newParams = Object.assign({}, pick(layoutSectionWidget, NON_BODY_ATTRIBUTES_TO_PICK_FROM_CONTENT_WIDGET), omit(layoutSectionWidget.body, ATTRIBUTES_NOT_NEEDED_INSIDE_PARAMS));
      ({
        tree
      } = tree.mergeIntoCellParams(cellName, newParams));
    }
  });
  if (isUngatedForMergeInDefaultValues) {
    // Step 2) Find all default image modules and set merge_in_default_values = false on them during
    // copy to content model (more info in https://git.hubteam.com/HubSpot/CMS-Developer-Infrastructure/issues/484)
    tree.allModules().forEach(cell => {
      const isDefaultImageModule = isLinkedImageModuleId(cell.getParams().module_id);
      if (isDefaultImageModule && cell.getValue().merge_in_default_values == null) {
        ({
          tree
        } = tree.mergeIntoCellValue(cell.getName(), {
          merge_in_default_values: false
        }));
      }
    });
  }
  return tree;
};
export const copyLayoutSectionFromSchemaToContent = (state, layoutSectionId, layoutSectionWidgets, {
  isUngatedForMergeInDefaultValues
}) => {
  let clonedTree = state.getIn(['schemaLayoutSectionTrees', layoutSectionId]);
  clonedTree = prepareNewlyClonedContentTree(clonedTree, layoutSectionWidgets, {
    isUngatedForMergeInDefaultValues
  });
  return state.setIn(['layoutSectionTrees', layoutSectionId], clonedTree);
};
export const copyLayoutSectionFromSchemaToContentIfNeeded = (state, layoutSectionId) => {
  if (!state.getIn(['layoutSectionTrees', layoutSectionId])) {
    state = copyLayoutSectionFromSchemaToContent(state, layoutSectionId, state.get('layoutSectionWidgets'), {
      isUngatedForMergeInDefaultValues: state.get('isUngatedForMergeInDefaultValues')
    });
  }
  return state;
};
export const getLayoutSectionTreeToModify = (state, layoutSectionId) => {
  if (!layoutSectionId) {
    return null;
  }
  state = copyLayoutSectionFromSchemaToContentIfNeeded(state, layoutSectionId);
  return state.getIn(['layoutSectionTrees', layoutSectionId]);
};
export const getLayoutSectionTree = (state, layoutSectionId) => {
  const layoutSectionTreePath = ['layoutSectionTrees', layoutSectionId];
  const schemaLayoutSectionTreePath = ['schemaLayoutSectionTrees', layoutSectionId];

  // First look for module in the page's content model layoutSection data.
  // And If not on the page's content model, look for the module in the page's layoutSection schema
  // (e.g. default layout data from the template)
  if (state.hasIn(layoutSectionTreePath)) {
    return state.getIn(layoutSectionTreePath);
  } else if (state.hasIn(schemaLayoutSectionTreePath)) {
    return state.getIn(schemaLayoutSectionTreePath);
  }
  return undefined;
};
const getLayoutSectionIds = state => {
  return state.get('schemaLayoutSectionTrees').keySeq().toArray();
};
export const findTreeWithCellName = (state, id) => {
  for (const layoutSectionId of getLayoutSectionIds(state)) {
    const tree = getLayoutSectionTree(state, layoutSectionId);
    if (tree.hasCell(id) || tree.hasStaticSectionModule(id)) {
      return tree;
    }
  }
  return undefined;
};
export const findTreeWithRowName = (state, id) => {
  for (const layoutSectionId of getLayoutSectionIds(state)) {
    const tree = getLayoutSectionTree(state, layoutSectionId);
    if (tree.hasRow(id)) {
      return tree;
    }
  }
  return undefined;
};
const addNewModuleToLayoutSectionWidgets = (state, layoutSectionId, newModuleSchemaJson) => {
  const layoutSectionWidgetsPath = ['layoutSectionWidgets', layoutSectionId];
  state = state.setIn(layoutSectionWidgetsPath, state.getIn(layoutSectionWidgetsPath, new Immutable.List()).push(Immutable.fromJS(newModuleSchemaJson)));
  return state;
};
const addClonedModuleToLayoutSectionWidgets = (state, layoutSectionId, oldName, newName) => {
  const layoutSectionWidgetsPath = ['layoutSectionWidgets', layoutSectionId];
  const oldLayoutSectionWidget = state.getIn(layoutSectionWidgetsPath).find(m => m.get('name') === oldName);

  // Skip over cloned rows and nested columns that are not in layout_section_widgets
  if (oldLayoutSectionWidget) {
    const newWidget = oldLayoutSectionWidget.merge({
      name: newName
    })
    // Don't copy the old widget/module's order (even though it isn't really used?)
    .delete('order')
    // Don't copy smart-content (for now)
    .delete('definition_id').delete('smart_objects').delete('smart_type');
    state = state.setIn(layoutSectionWidgetsPath, state.getIn(layoutSectionWidgetsPath, new Immutable.List()).push(newWidget));
  }
  return state;
};
const cleanupDeletedCellsFromLayoutSection = (state, deletedColumns, layoutSectionId) => {
  for (const deletedColumn of deletedColumns) {
    // Module groups/wrapper cells are not in layoutSectionWidgets, no need to delete them from there
    if (deletedColumn.isModule()) {
      const layoutSectionWidgetsPath = ['layoutSectionWidgets', layoutSectionId];
      const layoutSectionWidgetsIndex = state.getIn(layoutSectionWidgetsPath).findIndex(m => m.get('name') === deletedColumn.getName());
      if (layoutSectionWidgetsIndex === -1) {
        console.warn(`Error deleting ${deletedColumn.getName()}, it is not in layout section widgets`);
        EditorMetricsTracker.counter('Deleting module missing in schema layout section widgets').increment();
        continue;
      }
      layoutSectionWidgetsPath.push(layoutSectionWidgetsIndex);
      state = state.deleteIn(layoutSectionWidgetsPath);
    }
  }
  return state;
};
const removeUncustomizedWrappersStartingAtColumn = (tree, column) => {
  const {
    descendent
  } = pathOfAllUnnecessaryWrappersUpToDescendentToKeep(column);

  // If there are unnecessary wrappers, move the descendent up to the original column's parent
  // (which will trigger those unncessary wrappers to be automatically deleted)
  if (descendent !== column) {
    if (!descendent.isCell()) {
      throw new Error('Error looking up unnecessary wrappers, descendent should be a column or module');
    }
    const originalParentRowName = column.getParentName();
    return tree.appendColumn(originalParentRowName, {
      existingCellName: descendent.getName()
    });
  }
  return {};
};
const removeWrappersFromSiblingsInSectionIfNeeded = (modifiedTree, deletedColumns) => {
  for (const col of deletedColumns) {
    if (modifiedTree.hasRow(col.getParentName())) {
      const newParentRow = modifiedTree.findRow(col.getParentName());
      const wasColDeletedFromSection = newParentRow.getParent().isRoot();
      const onlyHadOneSibling = newParentRow.getNumberColumns() === 1;

      // If we just deleted column that triggered a section to go from 2 to 1 columns, see if we
      // can remove any unecessary wrapper columns around the last sibling
      if (wasColDeletedFromSection && onlyHadOneSibling) {
        modifiedTree.printTreeWithNames();
        const {
          tree: newTree,
          deletedColumn: moreDeletedColumns
        } = removeUncustomizedWrappersStartingAtColumn(modifiedTree, newParentRow.getColumns()[0]);
        modifiedTree = newTree || modifiedTree;
        deletedColumns = deletedColumns.concat(moreDeletedColumns || []);
        return {
          newTree: modifiedTree,
          deletedColumns
        };
      }
    }
  }
  return {
    newTree: modifiedTree,
    deletedColumns
  };
};
export const removeCellFromLayoutSection = (state, layoutSectionId, cellId, {
  shouldRemoveUnnecessaryWrappersForSingleColumnSection
}) => {
  // If this is the first time the layout section has been touched on the page, copy it to the content model
  state = copyLayoutSectionFromSchemaToContentIfNeeded(state, layoutSectionId);
  const tree = getLayoutSectionTree(state, layoutSectionId);
  const cell = tree.findCell(cellId);
  if (!tree || !cell) {
    throw new Error(`Error deleting ${cellId}, it is not in layout section ${layoutSectionId}`);
  }
  let {
    tree: newTree,
    deletedColumns /* , deletedRows */
  } = tree.removeCell(cellId);
  if (shouldRemoveUnnecessaryWrappersForSingleColumnSection) {
    ({
      newTree,
      deletedColumns
    } = removeWrappersFromSiblingsInSectionIfNeeded(newTree, deletedColumns));
  }
  state = state.setIn(['layoutSectionTrees', layoutSectionId], newTree);

  // Also delete all of the descendent modules from layoutSectionWidgets
  state = cleanupDeletedCellsFromLayoutSection(state, deletedColumns, layoutSectionId);
  return state;
};
export const removeRowFromLayoutSection = (state, layoutSectionId, rowId) => {
  // If this is the first time the layout section has been touched on the page, copy it to the content model
  state = copyLayoutSectionFromSchemaToContentIfNeeded(state, layoutSectionId);
  const tree = getLayoutSectionTree(state, layoutSectionId);
  const row = tree.findRow(rowId);
  if (!tree || !row) {
    throw new Error(`Error deleting ${rowId}, it is not in layout section ${layoutSectionId}`);
  }
  const {
    tree: newTree,
    deletedColumns /* , deletedRows */
  } = tree.removeRow(rowId);
  state = state.setIn(['layoutSectionTrees', layoutSectionId], newTree);

  // Also delete all of the descendent modules from layoutSectionWidgets
  state = cleanupDeletedCellsFromLayoutSection(state, deletedColumns, layoutSectionId);
  return state;
};
const correctLayoutSectionWidgets = (state, originLayoutSectionId, layoutSectionId, modifiedColumns) => {
  const originTreeList = state.getIn(['layoutSectionWidgets', originLayoutSectionId]) || new Immutable.List();
  const treeList = state.getIn(['layoutSectionWidgets', layoutSectionId]) || new Immutable.List();
  const modifiedColumnIds = modifiedColumns.map(column => column.getName());
  const movedWidgets = [];
  modifiedColumnIds.forEach(modifiedColumnId => {
    let movedWidget = originTreeList.find(widget => widget.get('name') === modifiedColumnId);
    if (movedWidget) {
      movedWidget = movedWidget.set('layout_section_id', layoutSectionId);
      movedWidgets.push(movedWidget);
    }
  });
  if (movedWidgets.length) {
    const filteredOriginList = originTreeList.filter(widget => !modifiedColumnIds.includes(widget.get('name')));
    state = state.setIn(['layoutSectionWidgets', originLayoutSectionId], filteredOriginList);
    state = state.setIn(['layoutSectionWidgets', layoutSectionId], treeList.push(...movedWidgets));
  }
  return state;
};
export const updateLayoutSectionTreeAndWidgets = ({
  layoutSectionId,
  originLayoutSectionId,
  newModuleSchemaJson,
  customSectionModuleSchemas,
  modifiedColumns,
  tree,
  originTree,
  mapOfClonedToOldNodeName,
  state
}) => {
  if (newModuleSchemaJson) {
    state = addNewModuleToLayoutSectionWidgets(state, layoutSectionId, newModuleSchemaJson);
  }
  if (customSectionModuleSchemas) {
    customSectionModuleSchemas.forEach(schema => {
      state = addNewModuleToLayoutSectionWidgets(state, layoutSectionId, schema);
    });
  }

  // Make sure that all cloned modules also show up in layout_section_widgets
  if (mapOfClonedToOldNodeName) {
    Object.keys(mapOfClonedToOldNodeName).forEach(newCloneName => {
      const oldName = mapOfClonedToOldNodeName[newCloneName];
      state = addClonedModuleToLayoutSectionWidgets(state, layoutSectionId, oldName, newCloneName);
      // Keep track if its a static section row
      // `mapOfClonedToOldStaticSectionName` is Used in `ModuleDomRenderContainer`
      if (tree.hasRow(oldName) && tree.findRow(oldName).isStaticSection()) {
        state = state.set('mapOfClonedToOldStaticSectionName',
        // Pass the map to the ModuleDomRenderContainer
        // So we know which static section to clone
        Immutable.Map(mapOfClonedToOldNodeName));
      }
      if (tree.hasCell(oldName)) {
        const treeNode = tree.findCell(oldName);
        if (treeNode && treeNode.isModule()) {
          state = state.set('clonedModulesPendingDomOperations', state.get('clonedModulesPendingDomOperations').add(Immutable.Map({
            originalModule: oldName,
            newModule: newCloneName
          })));
        }
      }
    });
  }

  // Don't attempt to update / set the temporary tree from
  // a custom section into redux.
  if (originTree && !customSectionModuleSchemas) {
    state = state.setIn(['layoutSectionTrees', originLayoutSectionId], originTree);
    state = correctLayoutSectionWidgets(state, originLayoutSectionId, layoutSectionId, modifiedColumns);
  }
  return state.setIn(['layoutSectionTrees', layoutSectionId], tree);
};

// MODULES
const updateTinymceUndoDataForModule = (state, id, tinymceUndoData) => {
  return state.mergeIn(['moduleMetaData', id, 'tinymceUndoData'], tinymceUndoData);
};
export const updateMetaDataForModule = (state, id, metaData = {}) => {
  // TODO branden maybe not the best way to do this
  if (metaData.tinymceUndoData) {
    state = updateTinymceUndoDataForModule(state, id, metaData.tinymceUndoData);
    if (getIsModuleReducerDiffingEnabled()) {
      set(SHARED_ACTION_DATA, [id, 'tinymceUndoData'], metaData.tinymceUndoData);
    }
    delete metaData.tinymceUndoData;
  }
  if (metaData.inlineRichTextFieldEditingMetaData) {
    // Copying the pattern set by 'tinymceUndoData'
    // Make sure that we're merging into 'inlineRichTextFieldEditingMetaData'
    // as opposed to overwriting it
    // Then delete `inlineRichTextFieldEditingMetaData` so that the `mergeIn`
    // following this one doesn't overwrite the existing inlineRichTextFieldEditingMetaData
    if (getIsModuleReducerDiffingEnabled()) {
      set(SHARED_ACTION_DATA, [id, 'inlineRichTextFieldEditingMetaData'], metaData.inlineRichTextFieldEditingMetaData);
    }
    state = state.mergeIn(['moduleMetaData', id, 'inlineRichTextFieldEditingMetaData'], metaData.inlineRichTextFieldEditingMetaData);
    delete metaData.inlineRichTextFieldEditingMetaData;
  }
  return state.mergeIn(['moduleMetaData', id], metaData);
};
export const incrementModuleEditVersion = ({
  state,
  id,
  defaultInitialVersion = 1
}) => {
  //The defaultInitialVersion handles a strange edge case where the image needs to be
  //resized on add and the moduleVersion would be set to 1 instead of 0
  //causing us not to be able to find the module on undo/redo
  let newVersion = defaultInitialVersion;
  const editVersionKey = ['moduleMetaData', id, 'editVersion'];
  const currentVersion = state.getIn(editVersionKey);
  if (currentVersion != null) {
    newVersion = currentVersion + 1;
  }
  return state.setIn(editVersionKey, newVersion);
};

// This is used to change UI editable field values like "src" or "text" on a module
export const mergeModuleBodyHelper = (state, id, partialBody, {
  moduleDefaultType
}) => {
  const tree = findTreeWithCellName(state, id);
  const isDefaultImageModule = moduleDefaultType === 'linked_image';
  if (tree) {
    state = copyLayoutSectionFromSchemaToContentIfNeeded(state, tree.getRootName());

    // Make sure to get cell reference in newly modified tree
    let newTree = state.getIn(['layoutSectionTrees', tree.getRootName()]);
    const cell = tree.findCell(id);
    ({
      tree: newTree
    } = newTree.mergeIntoCellParams(cell.getName(), partialBody));
    if (isDefaultImageModule && cell.hasValue() && cell.getValue().merge_in_default_values == null) {
      ({
        tree: newTree
      } = newTree.mergeIntoCellValue(cell.getName(), {
        merge_in_default_values: false
      }));
    }
    return state.setIn(['layoutSectionTrees', tree.getRootName()], newTree);
  }
  const moduleKey = getKeyForNonLayoutSection(state, id);
  const moduleBodyKey = [...moduleKey, 'body'];
  const modulemergeInDefaultFieldValuesKey = [...moduleKey, 'merge_in_default_values'];
  state = state.mergeIn(moduleBodyKey, Immutable.fromJS(partialBody));
  if (isDefaultImageModule && !state.hasIn(modulemergeInDefaultFieldValuesKey)) {
    state = state.setIn(modulemergeInDefaultFieldValuesKey, false);
  }
  return state;
};

// This is used to change top level data fields that we use like "Label" on a module
export const mergeModuleDataHelper = (state, id, partialData, layoutSectionId) => {
  const tree = findTreeWithCellName(state, id);
  if (tree) {
    state = copyLayoutSectionFromSchemaToContentIfNeeded(state, tree.getRootName());

    // Make sure to get cell reference in newly modified tree
    let newTree = state.getIn(['layoutSectionTrees', tree.getRootName()]);
    const cell = tree.findCell(id);
    ({
      tree: newTree
    } = newTree.mergeIntoCellValue(cell.getName(), partialData));
    const layoutSectionWidgets = state.getIn(['layoutSectionWidgets', layoutSectionId]);
    const widgetIndex = layoutSectionWidgets.findIndex(widget => widget.get('name') === id);
    const newLayoutSectionWidgets = layoutSectionWidgets.setIn([widgetIndex, 'label'], partialData.label);
    state = state.setIn(['layoutSectionWidgets', layoutSectionId], newLayoutSectionWidgets);
    return state.setIn(['layoutSectionTrees', tree.getRootName()], newTree);
  }
  const moduleKey = getKeyForNonLayoutSection(state, id);
  state = state.mergeIn(moduleKey, Immutable.fromJS(partialData));
  return state;
};
export const mergeLayoutFragmentDataHelper = (state, id, partialData, isRow) => {
  const tree = isRow ? findTreeWithRowName(state, id) : findTreeWithCellName(state, id);
  state = copyLayoutSectionFromSchemaToContentIfNeeded(state, tree.getRootName());

  // Make sure to get cell reference in newly modified tree
  let newTree = state.getIn(['layoutSectionTrees', tree.getRootName()]);
  if (isRow) {
    const row = tree.findRow(id);
    ({
      tree: newTree
    } = newTree.mergeIntoRowValue(row.getName(), partialData));
  } else {
    const cell = tree.findCell(id);
    ({
      tree: newTree
    } = newTree.mergeIntoCellValue(cell.getName(), partialData));
  }
  return state.setIn(['layoutSectionTrees', tree.getRootName()], newTree);
};
export const normalizeFlexColumnData = (contentFlexColumns, schemaFlexColumns = {}) => {
  if (contentFlexColumns) {
    const normalizedFlexColumns = {};
    const seen = {};

    // Ensure we keep the first instance of a named widget to keep in sync with the renderer,
    // so process each flex column in the render order
    const flexColumnIdsInOrder = Object.keys(schemaFlexColumns);
    flexColumnIdsInOrder.sort((a, b) => {
      const orderA = schemaFlexColumns[a] ? schemaFlexColumns[a].order : 0;
      const orderB = schemaFlexColumns[a] ? schemaFlexColumns[b].order : 0;
      return orderA - orderB;
    });
    flexColumnIdsInOrder.forEach(flexColumnId => {
      const flexColumn = contentFlexColumns[flexColumnId];
      if (flexColumn) {
        if (flexColumn.widgets) {
          const modules = [];
          flexColumn.widgets.forEach(module => {
            // Filter out any duplicate flex column widgets
            if (!seen[module.id]) {
              seen[module.id] = true;

              // In some cases, inside of a flex column, there can be a "nested" body object.
              // The renderer prefers the data in the nested body, so this attempts to replicate that behavior
              // We do it here instead of the selector because otherwise updates to the top level body would be
              // continually overwritten since the nested body takes precedence
              if (module.body && module.body.body && typeof module.body.body === 'object') {
                module.body = Object.assign({}, module.body, module.body.body);
                delete module.body.body;
              }

              // In some cases modules with smart content in a flex column will only have the
              // smart_objects list inside its body but not on the module itself. If that is the case
              // then this will copy over the body's smart_objects to the module.
              if (module.body && module.body.smart_objects && Array.isArray(module.body.smart_objects) && !module.smart_objects) {
                module.smart_objects = [...module.body.smart_objects];
              }
              modules.push(module);
            }
          });
          flexColumn.widgets = modules;
        }
        normalizedFlexColumns[flexColumnId] = flexColumn;
      }
    });
    return normalizedFlexColumns;
  }
  return contentFlexColumns;
};
export const maybeParseFlexAreaTree = ({
  content,
  isUngatedForDndAreasInCustomOrContentTreeSidebar,
  templateContainsFlexArea
}) => {
  let flexAreaTree = Immutable.Map();
  if (isUngatedForDndAreasInCustomOrContentTreeSidebar && templateContainsFlexArea) {
    const flexAreaData = parseFlexAreaDataForLayoutTree(content.flexAreas, content.widgets);
    flexAreaTree = flexAreaTree.set(DND_AREA_ID, importTreeFromLayoutDataApi(flexAreaData, {
      shouldPreventEmptyRows: false,
      CellClass: CustomCellTweakingAutoDeleteLogicPerTestFlag
    }));
  }
  return flexAreaTree;
};