import { find, omit } from 'underscore';
import { FONT_FORMAT_LIST } from 'ContentUtils/constants/Fonts';
import { getFieldDefault, getKeyfromPathString } from '../utils';
import { getDeepValue, isNullOrUndefined, setDeepValue, mergeDeep } from 'ContentUtils/helpers/ObjectHelpers';
import * as FieldTypes from 'ContentUtils/constants/CustomWidgetFieldTypes';
import InheritenceTypes from 'ContentUtils/constants/CustomWidgetInheritanceTypes';
function getFieldAndRemainingPathFromKey(fields, pathKey) {
  let field = null;
  let lastIndex = 0;
  for (let i = 0; i < pathKey.length; i++) {
    const _key = pathKey[i];
    const matchingField = field && field.children ? find(field.children, _child => _child.name === _key) : find(fields, _field => _field.name === _key);
    if (!matchingField) {
      break;
    } else {
      field = matchingField;
      lastIndex = i;
    }
  }
  return {
    inheritedField: field,
    fieldKey: pathKey.slice(0, lastIndex + 1),
    remainingPath: pathKey.slice(lastIndex + 1)
  };
}
const DEFAULT_BREAKPOINT = 'default';
function getInheritedValueFromField({
  allFields = [],
  breakpoint,
  inheritanceData,
  moduleData = {},
  pathKey,
  modulePath
}) {
  const value = getDeepValue(moduleData, pathKey);
  const {
    inheritedField,
    fieldKey,
    remainingPath
  } = getFieldAndRemainingPathFromKey(allFields, pathKey);
  if (!inheritedField) {
    return {
      value,
      fieldKey
    };
  }
  const {
    defaultValue: inheritedFieldDefault
  } = getFieldDefault({
    allFields,
    breakpoint,
    field: inheritedField,
    inheritanceData,
    moduleData,
    modulePath
  });
  if (isNullOrUndefined(value)) {
    return {
      fieldLabel: inheritedField.label,
      fieldKey,
      value: remainingPath.length ? getDeepValue(inheritedFieldDefault, remainingPath) : inheritedFieldDefault
    };
  } else if (inheritedField.type === FieldTypes.GROUP && inheritedFieldDefault && typeof inheritedFieldDefault === 'object') {
    const mergedValue = mergeDeep(inheritedFieldDefault, value);
    return {
      fieldLabel: inheritedField.label,
      fieldKey,
      value: remainingPath.length ? getDeepValue(mergedValue, remainingPath) : mergedValue
    };
  }
  return {
    value,
    fieldKey,
    fieldLabel: inheritedField.label
  };
}
function getBreakpointInheritedValue({
  allFields,
  field,
  inheritanceData = {},
  inheritedValueKey,
  moduleData,
  pathKey,
  modulePath
}) {
  const {
    id
  } = field;
  const {
    breakpointStyles
  } = inheritanceData;
  const fieldKey = getKeyfromPathString(id);
  const propertyPath = pathKey.slice(1);
  const breakpointName = inheritedValueKey[1];
  const isDefaultBreakpoint = breakpointName === DEFAULT_BREAKPOINT;

  // The default breakpoint refers to the field's base value or default
  if (isDefaultBreakpoint) {
    const {
      value
    } = getInheritedValueFromField({
      allFields,
      inheritanceData,
      moduleData,
      pathKey: [...fieldKey, ...propertyPath],
      modulePath
    });
    return value;
  }

  // If inheriting from a breakpoint try and grab the breakpoint's value from
  // breakpointStyles or grab the inherited breakpoint's default value
  const breakpointValueKey = [breakpointName, 'fieldStyles', ...fieldKey, ...propertyPath];
  let value = getDeepValue(breakpointStyles, breakpointValueKey);
  if (!isNullOrUndefined(value)) return value;
  const {
    defaultValue
  } = getFieldDefault({
    allFields,
    breakpoint: breakpointName,
    field,
    inheritanceData,
    moduleData,
    modulePath
  });
  value = defaultValue;
  return value && typeof value === 'object' && propertyPath.length ? getDeepValue(value, propertyPath) : value;
}
export function getInheritedDefaultValue({
  allFields,
  defaultValuePath,
  field,
  inheritanceData = {},
  moduleData,
  modulePath
}) {
  const inheritedValueKey = defaultValuePath && getKeyfromPathString(defaultValuePath);
  if (inheritedValueKey && inheritedValueKey.length > 1) {
    const inheritanceTypeKey = inheritedValueKey[0];
    const pathKey = inheritedValueKey.slice(1);
    switch (inheritanceTypeKey) {
      case InheritenceTypes.brand_settings:
      case InheritenceTypes.brandSettings:
        {
          const {
            brandSettings = {}
          } = inheritanceData;
          return {
            inheritanceType: InheritenceTypes.brandSettings,
            value: getDeepValue(brandSettings, pathKey)
          };
        }
      case InheritenceTypes.theme:
        {
          const {
            themeFields = [],
            themeSettings = {}
          } = inheritanceData;
          const {
            value,
            fieldKey,
            fieldLabel
          } = getInheritedValueFromField({
            allFields: themeFields,
            inheritanceData,
            moduleData: themeSettings,
            pathKey,
            modulePath
          });
          return {
            inheritanceType: InheritenceTypes.theme,
            inheritanceFieldKey: fieldKey,
            inheritanceFieldLabel: fieldLabel,
            value
          };
        }
      case InheritenceTypes.breakpoints:
        {
          const value = getBreakpointInheritedValue({
            allFields,
            field,
            inheritanceData,
            inheritedValueKey,
            moduleData,
            pathKey,
            modulePath
          });
          return {
            value
          };
        }
      default:
        {
          const {
            value,
            fieldKey,
            fieldLabel
          } = getInheritedValueFromField({
            allFields,
            inheritanceData,
            moduleData,
            pathKey,
            modulePath
          });
          return {
            inheritanceType: InheritenceTypes.module,
            inheritanceFieldKey: fieldKey,
            inheritanceFieldLabel: fieldLabel,
            value
          };
        }
    }
  }
  return {
    value: null
  };
}
const EDITOR_STYLES_ALIAS_KEY = 'css';
const EDITOR_STYLES_SIZE_VALUE_ALIAS = 'value';
const EDITOR_STYLES_SIZE_UNITS_ALIAS = 'units';
const EDITOR_STYLES_FONT_FAMILY_ALIAS = 'font';
const EDITOR_STYLES_FONT_FALLBACK_ALIAS = 'fallback';
const DEFAULT_MODULE_PATH = '@hubspot/';
export function getAliasedDefaultValue({
  defaultValue,
  field,
  moduleData,
  modulePath
}) {
  const {
    aliases_mapping: aliasesMapping
  } = field;
  const {
    property_aliases_paths: propertyAliasesPaths
  } = aliasesMapping || {};
  if (propertyAliasesPaths) {
    for (const propertyPath of Object.keys(propertyAliasesPaths).reverse()) {
      for (const alias of propertyAliasesPaths[propertyPath]) {
        const aliasPathKey = alias.split('.');
        const value = getDeepValue(moduleData, aliasPathKey);
        if (value !== undefined) {
          if (propertyPath === field.name) {
            defaultValue = value;
          } else {
            if (!(defaultValue && typeof defaultValue === 'object')) {
              defaultValue = {};
            }
            const keyPath = getKeyfromPathString(propertyPath.split('.').slice(1).join('.'));
            defaultValue = setDeepValue(defaultValue, keyPath, value);
          }
          break;
        }
        const editorStylesAlias = aliasPathKey[aliasPathKey.length - 1];
        const isEditorStylesAlias = aliasPathKey[0] === EDITOR_STYLES_ALIAS_KEY;
        const isDefaultModule = modulePath.startsWith(DEFAULT_MODULE_PATH);
        if (isDefaultModule && isEditorStylesAlias) {
          const isEditorStylesSizeValue = editorStylesAlias === EDITOR_STYLES_SIZE_VALUE_ALIAS || editorStylesAlias === EDITOR_STYLES_SIZE_UNITS_ALIAS;
          if (isEditorStylesSizeValue) {
            const aliasedSizeKey = aliasPathKey.slice(0, -1);
            const sizeString = getDeepValue(moduleData, aliasedSizeKey);
            if (typeof sizeString === 'string') {
              const sizeProperties = sizeString.match(/(-?\d*\.?\d*)(.*)/);
              const sizeValue = sizeProperties && sizeProperties.length === 3 ? parseFloat(sizeProperties[1]) : undefined;
              const sizeUnits = sizeProperties && sizeProperties.length === 3 ? sizeProperties[2] : undefined;
              if (!isNullOrUndefined(sizeValue) && sizeUnits) {
                if (!(defaultValue && typeof defaultValue === 'object')) {
                  defaultValue = {};
                }
                let valueToSet = null;
                if (editorStylesAlias === EDITOR_STYLES_SIZE_VALUE_ALIAS) {
                  valueToSet = sizeValue;
                }
                if (editorStylesAlias === EDITOR_STYLES_SIZE_UNITS_ALIAS) {
                  valueToSet = sizeUnits;
                }
                const keyPath = getKeyfromPathString(propertyPath.split('.').slice(1).join('.'));
                defaultValue = setDeepValue(defaultValue, keyPath, valueToSet);
                break;
              }
            }
          }
          const isEditorStylesFontValue = editorStylesAlias === EDITOR_STYLES_FONT_FAMILY_ALIAS || editorStylesAlias === EDITOR_STYLES_FONT_FALLBACK_ALIAS;
          if (isEditorStylesFontValue) {
            const aliasedFontKey = aliasPathKey.slice(0, -1);
            const fontString = getDeepValue(moduleData, aliasedFontKey);
            if (fontString) {
              const fontsList = fontString.split(',');
              if (fontsList.length > 0) {
                if (!(defaultValue && typeof defaultValue === 'object')) {
                  defaultValue = {};
                }
                let valueToSet = null;
                if (editorStylesAlias === EDITOR_STYLES_FONT_FAMILY_ALIAS) {
                  const fontFamily = fontsList[0];
                  valueToSet = FONT_FORMAT_LIST[fontFamily] ? FONT_FORMAT_LIST[fontFamily] : fontsList[0];
                }
                if (editorStylesAlias === EDITOR_STYLES_FONT_FALLBACK_ALIAS) {
                  valueToSet = fontsList.slice(1).join(',').trim();
                }
                const keyPath = getKeyfromPathString(propertyPath.split('.').slice(1).join('.'));
                defaultValue = setDeepValue(defaultValue, keyPath, valueToSet);
                break;
              }
            }
          }
        }
      }
    }
  }
  return defaultValue;
}
export function getDataTokenInheritedValue(module, fieldKey) {
  if (module && module.dataTokenPaths) {
    return module.dataTokenPaths[fieldKey] || null;
  }
  return null;
}
const ExternalInheritanceTypes = omit(InheritenceTypes, 'module');
function doesFieldContainExternalInheritance(field) {
  const {
    inherited_value,
    limited_options: limitedOptions
  } = field;
  if (inherited_value) {
    const {
      default_value_path: defaultValuePath,
      property_value_paths: propertyValuePaths
    } = inherited_value;
    const defaultValueKey = defaultValuePath && defaultValuePath.split('.');
    if (defaultValueKey && defaultValueKey.length) {
      return !!ExternalInheritanceTypes[defaultValueKey[0]];
    }
    if (propertyValuePaths && typeof propertyValuePaths === 'object') {
      return Object.keys(propertyValuePaths).some(key => {
        const path = propertyValuePaths[key];
        const propertyKey = path && path.split('.');
        return propertyKey && propertyKey.length && !!ExternalInheritanceTypes[propertyKey[0]];
      });
    }
  }
  if (limitedOptions) {
    return limitedOptions.some(path => {
      const propertyKey = path && path.split('.');
      return propertyKey && propertyKey.length && !!ExternalInheritanceTypes[propertyKey[0]];
    });
  }
  return false;
}
export function doFieldsContainExternalInheritance(fields) {
  return fields.some(_field => {
    if (doesFieldContainExternalInheritance(_field)) return true;
    if (_field.children) {
      return doFieldsContainExternalInheritance(_field.children);
    }
    return false;
  });
}