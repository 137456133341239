import { createSelector } from 'reselect';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { TABS, getInitialCollaborationSidebarState } from 'collaboration-sidebar';
import { getIsUngatedForCommentPopOver } from 'ContentEditorUI/redux/selectors/authSelectors';
const EMPTY_STATE = getInitialCollaborationSidebarState();
export const getCollaborationSidebarState = basicSelector(state => state.collaborationSidebar || EMPTY_STATE);
export const getIsCommentModeActive = createSelector([getCollaborationSidebarState], collaborationSidebarState => collaborationSidebarState.isCommentModeActive);
export const getIsOnCommentingTab = createSelector([getCollaborationSidebarState], collaborationSidebarState => collaborationSidebarState.isOpen && collaborationSidebarState.tab === TABS.COMMENTS);
export const getHasUnrecoverableError = createSelector([getCollaborationSidebarState], collaborationSidebarState => collaborationSidebarState.hasUnrecoverableError);
export const makeGetHasComments = name => {
  return createSelector([getCollaborationSidebarState, getIsUngatedForCommentPopOver], (collaborationSidebarState, isUngatedForCommentPopOver) => {
    const modulesWithReplies = collaborationSidebarState.modulesWithReplies;
    return isUngatedForCommentPopOver && modulesWithReplies && modulesWithReplies.hasOwnProperty(name);
  });
};