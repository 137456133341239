import { createSelector } from 'reselect';
import { basicSelector } from './helpers';
import { getHasCtaModulesWithoutAccess, getHasHsVideoModulesWithoutAccess, getContentHasPaymentsFieldWithoutAccess, getContentHasPersonalizationTokensWithoutAccess, getHasSmartContentWithoutAccess
// @ts-expect-error Not typed yet
} from 'ContentEditorUI/redux/selectors/downgradeSelectors';
import { getIsAbTestWithoutAccess } from 'ContentEditorUI/redux/selectors/abTestSelectors';
// @ts-expect-error Not typed yet
import { getHasSeenScopesHaveChangedModal } from 'ContentEditorUI/redux/selectors/settingsSelectors';
// @ts-expect-error Not typed yet
import { getIsMABExperimentWithoutAccess } from 'ContentEditorUI/redux/selectors/mabExperimentSelectors';
import { getIsPageOnPagebuilderDomain } from './portalDomainsSelectors';
import { getCustomDomainSetupNextReminderDate } from './cmsSettingsSelectors';
import { getFetchCmsSettingsRequest } from './requestStatesSelectors';
import I18n from 'I18n';
import { requestSucceeded } from 'redux-request-state/util/requestStateCheckers';

// change this when we migrate downgrade,abtest,settings,mabexperiment selectors

const getModalInfo = basicSelector(state => {
  return state.modals;
});
const createModalInfoSelector = key => {
  return createSelector([getModalInfo], modalInfo => modalInfo[key]);
};
export const getOpenModal = createModalInfoSelector('openModal');
export const getOpenModalMetadata = createSelector([getOpenModal, getModalInfo], (openModal, modalInfo) => !!openModal && modalInfo.modalMetadata);
export const getOpenPopOutEditorKey = createModalInfoSelector('openPopOutEditorKey');
export const getHasOpenPopOutEditor = createSelector([getOpenPopOutEditorKey], openPopOutEditorKey => !!openPopOutEditorKey && openPopOutEditorKey.length > 0);
export const getPublishModalOpen = createModalInfoSelector('publishModalOpen');
export const isPublishModalOpen = createSelector([getPublishModalOpen], publishModalOpenStatus => !!publishModalOpenStatus);
export const getIsPublishOptionsModalOpen = createModalInfoSelector('publishOptionsModalOpen');
export const isApplyChangesModalOpen = createModalInfoSelector('applyChangesModalOpen');
export const getEditTemplateModalIsOpen = createModalInfoSelector('editTemplateModalIsOpen');
export const getHasScopesHaveChangedModalBeenDismissed = createModalInfoSelector('scopesHaveChangedModalWasDismissed');
const getHasAdvancedContentWithoutAccess = createSelector([getHasCtaModulesWithoutAccess, getHasHsVideoModulesWithoutAccess, getIsAbTestWithoutAccess, getHasSmartContentWithoutAccess, getIsMABExperimentWithoutAccess], (hasCtasModulesWithoutAccess, hasHsVideoModulesWithoutAccess, isABTestWithoutAccess, hasSmartContentWithoutAccess, isMABExperimentWithoutAccess) => hasCtasModulesWithoutAccess || hasHsVideoModulesWithoutAccess || isABTestWithoutAccess || hasSmartContentWithoutAccess || isMABExperimentWithoutAccess);
const getHasAdvancedContentWithoutAccessAndHasNotSeenModal = createSelector([getHasAdvancedContentWithoutAccess, getHasSeenScopesHaveChangedModal], (hasAdvancedContentWithoutAccess, hasSeenScopesHaveChangedModal) => Boolean(hasAdvancedContentWithoutAccess && !hasSeenScopesHaveChangedModal));
export const getContentIsEligleForScopesHaveChangedModal = createSelector([getHasAdvancedContentWithoutAccessAndHasNotSeenModal, getContentHasPaymentsFieldWithoutAccess, getContentHasPersonalizationTokensWithoutAccess], (hasAdvancedContentWithoutAccessAndHasNotSeenModal, contentHasPaymentsFieldWithoutAccess, contentHasPersonalizationTokensWithoutAccess) => Boolean(hasAdvancedContentWithoutAccessAndHasNotSeenModal || contentHasPaymentsFieldWithoutAccess || contentHasPersonalizationTokensWithoutAccess));
export const getShouldShowScopesHaveChangedModal = createSelector([getContentIsEligleForScopesHaveChangedModal, getHasScopesHaveChangedModalBeenDismissed], (isContentElligleForScopesHaveChangedModal, hasScopesHaveChangedModalBeenDismissed) => isContentElligleForScopesHaveChangedModal && !hasScopesHaveChangedModalBeenDismissed);
export const getIsShowingMatchingAppModal = createModalInfoSelector('showMatchingAppModal');
export const getMatchingAppOpenLocation = createModalInfoSelector('matchingAppOpenLocation');
export const getSelectedUpgradableModulePQL = createModalInfoSelector('selectedUpgradableModule');
export const getPatagoniaSettingsModalActiveNavItem = createModalInfoSelector('patagoniaSettingsModalActiveNavItem');
export const getRunTestModalOpen = createModalInfoSelector('runTestModalOpen');
export const getOpenTestType = createModalInfoSelector('openTestType');
export const getPostPublishDomainSetupModalOpen = createModalInfoSelector('postPublishDomainSetupModalOpen');
export const getShouldOpenPostPublishCustomDomainSetupModal = createSelector([getIsPageOnPagebuilderDomain, getCustomDomainSetupNextReminderDate, getFetchCmsSettingsRequest], (isDomainOnFreePageBuilder, customDomainSetupNextReminderDate, fetchCmsSettingsRequest) => {
  if (isDomainOnFreePageBuilder && requestSucceeded(fetchCmsSettingsRequest)) {
    if (!customDomainSetupNextReminderDate) {
      return true;
    } else if (customDomainSetupNextReminderDate !== -1) {
      const currentDate = I18n.moment().valueOf();
      return currentDate > customDomainSetupNextReminderDate;
    }
  }
  return false;
});