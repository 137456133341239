import I18n from 'I18n';
import { CONTENT_PLATFORM_PROPERTY_NAME } from '../constants/ContentPlatformPropertyName';
import { getContentPropertyValue } from './getContentPropertyValue';
import { VIOLATION_STATES } from '../constants/ContentSecurityStates';

/**
 * Returns an array of all unique content ids of the @crmObjects
 * including the content ids of translated content objects,
 * which are part of a multi-language group with an object in @crmObjects
 */
export function getAllUniqueContentIdsFromCrmObjects(crmObjects) {
  const contentIds = new Set();
  crmObjects.forEach(crmObject => {
    const contentId = getContentPropertyValue(crmObject, CONTENT_PLATFORM_PROPERTY_NAME.ID);
    if (contentId !== undefined) {
      contentIds.add(contentId);
    }
    if (crmObject.associatedObjects && crmObject.associatedObjects.OBJECT_TO_TRANSLATIONS) {
      crmObject.associatedObjects.OBJECT_TO_TRANSLATIONS.forEach(translation => {
        const translatedContentId = getContentPropertyValue(translation, CONTENT_PLATFORM_PROPERTY_NAME.ID);
        if (translatedContentId !== undefined) {
          contentIds.add(translatedContentId);
        }
      });
    }
  });
  return Array.from(contentIds);
}
export const getContentAssociatedObjects = (crmObject, associatedObjectType) => {
  if (crmObject.associatedObjects && crmObject.associatedObjects[associatedObjectType]) {
    return crmObject.associatedObjects[associatedObjectType];
  }
  return [];
};
export const getAssociatedContentTranslations = crmObject => getContentAssociatedObjects(crmObject, 'OBJECT_TO_TRANSLATIONS');
export const getAssociatedContentGroups = crmObject => getContentAssociatedObjects(crmObject, 'OBJECT_TO_CONTENT_GROUP');
function contentLegacyStateIsPublished(content) {
  return getContentPropertyValue(content, CONTENT_PLATFORM_PROPERTY_NAME.STATE) === 'PUBLISHED';
}
function contentLegacyStateIsDraft(content) {
  return getContentPropertyValue(content, CONTENT_PLATFORM_PROPERTY_NAME.STATE) === 'DRAFT';
}
function contentLegacyStateIsScheduled(content) {
  const scheduledPublishDate = getContentPropertyValue(content, CONTENT_PLATFORM_PROPERTY_NAME.SCHEDULED_PUBLISH_DATE);
  return contentLegacyStateIsDraft(content) && !!scheduledPublishDate && scheduledPublishDate > Date.now();
}
function contentLegacyStateIsPublishedWithScheduledUpdate(content) {
  const scheduledPublishDate = getContentPropertyValue(content, CONTENT_PLATFORM_PROPERTY_NAME.SCHEDULED_PUBLISH_DATE);
  return contentLegacyStateIsPublished(content) && !!scheduledPublishDate;
}
function contentLegacyStateIsScheduledToBeUnpublished(content) {
  const scheduledUnpublishDate = getContentPropertyValue(content, CONTENT_PLATFORM_PROPERTY_NAME.SCHEDULED_UNPUBLISH_DATE);
  return contentLegacyStateIsPublished(content) && !!scheduledUnpublishDate && scheduledUnpublishDate > Date.now();
}
export function contentIsInViolation(content) {
  const securityState = getContentPropertyValue(content, CONTENT_PLATFORM_PROPERTY_NAME.SECURITY_STATE);
  if (securityState) {
    return VIOLATION_STATES.includes(securityState);
  }
  return false;
}
export const contentIsScheduled = content => {
  const resolvedState = getContentPropertyValue(content, CONTENT_PLATFORM_PROPERTY_NAME.RESOLVED_STATE);
  if (resolvedState) {
    return resolvedState === 'SCHEDULED';
  }
  return contentLegacyStateIsScheduled(content);
};
export const contentIsDraft = content => {
  const resolvedState = getContentPropertyValue(content, CONTENT_PLATFORM_PROPERTY_NAME.RESOLVED_STATE);
  if (resolvedState) {
    return resolvedState === 'DRAFT';
  }
  return contentLegacyStateIsDraft(content);
};
export const contentIsPublishedWithScheduledUpdate = content => {
  const resolvedState = getContentPropertyValue(content, CONTENT_PLATFORM_PROPERTY_NAME.RESOLVED_STATE);
  if (resolvedState) {
    return resolvedState === 'SCHEDULED_UPDATE';
  }
  return contentLegacyStateIsPublishedWithScheduledUpdate(content);
};
export const contentIsScheduledToBeUnpublished = content => {
  const resolvedState = getContentPropertyValue(content, CONTENT_PLATFORM_PROPERTY_NAME.RESOLVED_STATE);
  if (resolvedState) {
    return resolvedState === 'SCHEDULED_UNPUBLISH';
  }
  return contentLegacyStateIsScheduledToBeUnpublished(content);
};
export const contentIsPublished = content => {
  const resolvedState = getContentPropertyValue(content, CONTENT_PLATFORM_PROPERTY_NAME.RESOLVED_STATE);
  if (resolvedState) {
    return resolvedState === 'PUBLISHED' || contentIsPublishedWithScheduledUpdate(content) || contentIsScheduledToBeUnpublished(content);
  }
  return contentLegacyStateIsPublished(content);
};
export const contentWasPublishedInLastTwoMinutes = content => {
  const isPublished = contentIsPublished(content);
  const publishDate = getContentPropertyValue(content, CONTENT_PLATFORM_PROPERTY_NAME.PUBLISHED_AT);
  return isPublished && publishDate ? I18n.moment().valueOf() - publishDate <= 120000 : false;
};
export function getContentStatusType(content) {
  if (contentIsInViolation(content)) {
    return 'danger';
  }
  if (contentIsScheduled(content)) {
    return 'scheduled';
  }
  if (contentIsDraft(content)) {
    return 'draft';
  }
  if (contentIsPublished(content)) {
    return 'published';
  }
  return 'default';
}
export function contentHasTranslations(content) {
  return getAssociatedContentTranslations(content).length > 0;
}
export function contentIsTranslation(content) {
  const translatedFromId = getContentPropertyValue(content, CONTENT_PLATFORM_PROPERTY_NAME.TRANSLATED_FROM_ID);
  return !!translatedFromId;
}
export function contentIsPartOfMultiLanguageGroup(content) {
  return contentHasTranslations(content) && contentIsTranslation(content);
}
export function getMlangGroupSize(content) {
  const translatedContent = getAssociatedContentTranslations(content);
  return translatedContent.length + 1;
}
export function getTranslatedContentObjectIds(content) {
  const translatedContent = getAssociatedContentTranslations(content);
  return translatedContent.map(translation => {
    return getContentPropertyValue(translation, CONTENT_PLATFORM_PROPERTY_NAME.ID);
  });
}
export function contentIsArchived(content) {
  return getContentPropertyValue(content, CONTENT_PLATFORM_PROPERTY_NAME.ARCHIVED_IN_DASHBOARD);
}
const FREE_DOMAIN_REGEX = /^.+\.hubspotpagebuilder(qa)?(-[A-z0-9]+)?\..+/;
export const getIsContentDomainOnFreePageBuilder = crmObject => {
  const domain = getContentPropertyValue(crmObject, CONTENT_PLATFORM_PROPERTY_NAME.DOMAIN);
  return domain ? new RegExp(FREE_DOMAIN_REGEX).test(domain) : false;
};