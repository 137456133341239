// @ts-expect-error not typed
import parseModuleId from 'ContentEditorUI/utils/parseModuleId';
import { omit, pick } from 'underscore';
import { ATTRIBUTES_NOT_NEEDED_INSIDE_PARAMS, NON_BODY_ATTRIBUTES_TO_PICK_FROM_CONTENT_WIDGET
// @ts-expect-error not typed
} from 'ContentEditorUI/data/moduleTransformHelpers';
import { pathOfAllUnnecessaryWrappersUpToDescendentToKeep } from 'layout-data-lib/CellAndRowsTree/helpers';
// @ts-expect-error not typed
import { isLinkedImageModuleId } from 'ContentEditorUI/data/moduleUtils';
import { EditorMetricsTracker } from 'ContentEditorUI/utils/metricsTracker';
// @ts-expect-error not typed
import { importTreeFromLayoutDataApi } from 'layout-data-lib/LayoutDataTree/serialize';
// @ts-expect-error not typed
import CustomCellTweakingAutoDeleteLogicPerTestFlag from 'ContentEditorUI/data/tree/CustomCellTweakingAutoDeleteLogicPerTestFlag';
import { DND_AREA_ID, parseFlexAreaDataForLayoutTree } from 'ContentEditorUI/utils/email/contentTreeUtils';
// @ts-expect-error not typed
import { SHARED_ACTION_DATA } from './moduleReducerUtils';
import has from 'hs-lodash/has';
import get from 'hs-lodash/get';
import set from 'ContentEditorUI/types/set';
import unset from 'hs-lodash/unset';
import size from 'hs-lodash/size';
import mergeIn from '../lib/mergeIn';
import { isDraft, original } from 'immer';
import cloneDeep from './cloneDeep';
// WIDGET CONTAINERS
const getWidgetContainerInfo = (draft, id) => {
  id = parseModuleId(id);
  for (const [widgetContainerKey, widgetContainer] of Object.entries(draft.widgetContainers)) {
    const entryIndex = widgetContainer.widgets.findIndex(widget => parseModuleId(widget.id) === id && !widget.deleted_at);
    if (entryIndex >= 0) {
      return {
        widgetContainerKey,
        order: entryIndex
      };
    }
  }
  // TODO: How should we handle this case
  return {};
};
export const getKeyForNonLayoutSectionImmer = (draft, id) => {
  if (has(draft, ['widgetsInRichText', id])) {
    return ['widgetsInRichText', id];
  }
  if (has(draft, ['schemaWidgets', id])) {
    return ['widgets', id];
  }
  const {
    widgetContainerKey,
    order
  } = getWidgetContainerInfo(draft, id);
  return ['widgetContainers', widgetContainerKey, 'widgets', order];
};
const resetOrdersInContainer = (draft, containerKey) => {
  const widgetList = get(draft, ['widgetContainers', containerKey, 'widgets']);
  widgetList.forEach((module, i) => {
    // Ehh, I think immer might refreeze this if we mutate it, so maybe we
    // should leave this check?
    if (i !== module.order) {
      module.order = i;
    }
  });
};
export const addModuleToFlexColumnHelperImmer = (draft, columnId, index, module) => {
  let widgetContainer = get(draft, ['widgetContainers', columnId]);
  if (!widgetContainer) {
    widgetContainer = get(draft, ['schemaWidgetContainers', columnId]);
    if (!widgetContainer) {
      throw new Error(`Cannot find widget container with given id: ${columnId}`);
    }
    const copiedWidgetContainer = cloneDeep(original(widgetContainer));
    // TODO: There probably was a bug here at some point because `id` is not guaranteed to exist
    // on the schema widget containers
    // But going to leave this commented out for now as it will likely produce diffs
    // copiedWidgetContainer.widgets.forEach((widget) => {
    //   const result = widget as unknown as ContentFlexColumnModule;
    //   result.id = widget.key;
    // });

    set(draft, ['widgetContainers', columnId], copiedWidgetContainer);
    widgetContainer = copiedWidgetContainer;
  }
  const widgetContainerWidgets = widgetContainer.widgets || [];
  const insertIndex = isNaN(index) ? size(widgetContainerWidgets) : index;
  widgetContainerWidgets.splice(insertIndex, 0, module);
  set(draft, ['widgetContainers', columnId, 'widgets'], widgetContainerWidgets);
  resetOrdersInContainer(draft, columnId);
};
export const removeModuleImmer = (draft, id) => {
  const {
    widgetContainerKey,
    order
  } = getWidgetContainerInfo(draft, id);
  const widgetContainer = get(draft, ['widgetContainers', widgetContainerKey, 'widgets']);
  widgetContainer.splice(order, 1);
  resetOrdersInContainer(draft, widgetContainerKey);
};

// LAYOUT SECTIONS
const prepareNewlyClonedContentTree = (tree, layoutSectionWidgets, {
  isUngatedForMergeInDefaultValues
}) => {
  // NOTE: Be very careful with cloning existing items in the draft
  if (isDraft(layoutSectionWidgets)) {
    const originalLayoutSectionWidgets = original(layoutSectionWidgets);
    if (originalLayoutSectionWidgets) {
      layoutSectionWidgets = originalLayoutSectionWidgets;
    }
  }
  const mapOfLayoutSectionWidgetsForTree = Object.fromEntries(get(layoutSectionWidgets, tree.getRootName(), []).map(w => [w.name, window.structuredClone(w)]));

  // Step 1) Bring along previous edits that live outside the D&D area (more info in
  // https://git.hubteam.com/HubSpot/ContentEditorUI/pull/4217)
  tree.allModules().map(cell => cell.getName()).forEach(cellName => {
    const layoutSectionWidget = mapOfLayoutSectionWidgetsForTree[cellName];

    // If it exists, merge the (cleaned up) body from the layout section widget with the other.
    // non-body attributes (and sigh, more params <-> body pain that we need to someday deal with)
    if (layoutSectionWidget) {
      const newParams = Object.assign({}, pick(layoutSectionWidget, NON_BODY_ATTRIBUTES_TO_PICK_FROM_CONTENT_WIDGET), omit(layoutSectionWidget.body, ATTRIBUTES_NOT_NEEDED_INSIDE_PARAMS));
      ({
        tree
      } = tree.mergeIntoCellParams(cellName, newParams));
    }
  });
  if (isUngatedForMergeInDefaultValues) {
    // Step 2) Find all default image modules and set merge_in_default_values = false on them during
    // copy to content model (more info in https://git.hubteam.com/HubSpot/CMS-Developer-Infrastructure/issues/484)
    tree.allModules().forEach(cell => {
      const isDefaultImageModule = isLinkedImageModuleId(cell.getParams().module_id);
      if (isDefaultImageModule && cell.getValue().merge_in_default_values == null) {
        ({
          tree
        } = tree.mergeIntoCellValue(cell.getName(), {
          merge_in_default_values: false
        }));
      }
    });
  }
  return tree;
};
export const copyLayoutSectionFromSchemaToContentImmer = (draft, layoutSectionId, layoutSectionWidgets, {
  isUngatedForMergeInDefaultValues
}) => {
  let clonedTree = get(draft, ['schemaLayoutSectionTrees', layoutSectionId]);
  clonedTree = prepareNewlyClonedContentTree(clonedTree, layoutSectionWidgets, {
    isUngatedForMergeInDefaultValues
  });
  set(draft, ['layoutSectionTrees', layoutSectionId], clonedTree);
};
export const copyLayoutSectionFromSchemaToContentIfNeededImmer = (draft, layoutSectionId) => {
  if (!get(draft, ['layoutSectionTrees', layoutSectionId])) {
    copyLayoutSectionFromSchemaToContentImmer(draft, layoutSectionId, draft.layoutSectionWidgets, {
      isUngatedForMergeInDefaultValues: draft.isUngatedForMergeInDefaultValues
    });
  }
};
export const getLayoutSectionTreeToModifyImmer = (draft, layoutSectionId) => {
  if (!layoutSectionId) {
    return null;
  }
  copyLayoutSectionFromSchemaToContentIfNeededImmer(draft, layoutSectionId);
  return get(draft, ['layoutSectionTrees', layoutSectionId]);
};
export const getLayoutSectionTreeImmer = (draft, layoutSectionId) => {
  const layoutSectionTreePath = ['layoutSectionTrees', layoutSectionId];
  const schemaLayoutSectionTreePath = ['schemaLayoutSectionTrees', layoutSectionId];

  // First look for module in the page's content model layoutSection data.
  // And If not on the page's content model, look for the module in the page's layoutSection schema
  // (e.g. default layout data from the template)
  if (has(draft, layoutSectionTreePath)) {
    return get(draft, layoutSectionTreePath);
  } else if (has(draft, schemaLayoutSectionTreePath)) {
    return get(draft, schemaLayoutSectionTreePath);
  }
  return undefined;
};
const getLayoutSectionIds = draft => {
  return Object.keys(draft.schemaLayoutSectionTrees);
};
export const findTreeWithCellNameImmer = (draft, id) => {
  for (const layoutSectionId of getLayoutSectionIds(draft)) {
    const tree = getLayoutSectionTreeImmer(draft, layoutSectionId);
    if (tree.hasCell(id) || tree.hasStaticSectionModule(id)) {
      return tree;
    }
  }
  return undefined;
};
export const findTreeWithRowNameImmer = (draft, id) => {
  for (const layoutSectionId of getLayoutSectionIds(draft)) {
    const tree = getLayoutSectionTreeImmer(draft, layoutSectionId);
    if (tree.hasRow(id)) {
      return tree;
    }
  }
  return undefined;
};
const addNewModuleToLayoutSectionWidgets = (draft, layoutSectionId, newModuleSchemaJson) => {
  const layoutSectionWidgetsPath = ['layoutSectionWidgets', layoutSectionId];
  let existingLayoutSectionWidgets = get(draft, layoutSectionWidgetsPath);
  if (!existingLayoutSectionWidgets) {
    existingLayoutSectionWidgets = [newModuleSchemaJson];
    set(draft, layoutSectionWidgetsPath, existingLayoutSectionWidgets);
  } else {
    existingLayoutSectionWidgets.push(newModuleSchemaJson);
  }
};
const addClonedModuleToLayoutSectionWidgets = (draft, layoutSectionId, oldName, newName) => {
  const layoutSectionWidgetsPath = ['layoutSectionWidgets', layoutSectionId];
  // TODO: Maybe use `original` here for better perf
  const oldLayoutSectionWidget = get(draft, layoutSectionWidgetsPath).find(m => m.name === oldName);

  // Skip over cloned rows and nested columns that are not in layout_section_widgets
  // Have to be reaaally careful about this part, cause Immutables are implicit clones
  if (oldLayoutSectionWidget) {
    const newWidget = Object.assign({}, oldLayoutSectionWidget, {
      name: newName
    });
    delete newWidget.order;
    delete newWidget.definition_id;
    delete newWidget.smart_objects;
    delete newWidget.smart_type;
    get(draft, layoutSectionWidgetsPath).push(newWidget);
  }
};
const cleanupDeletedCellsFromLayoutSection = (draft, deletedColumns, layoutSectionId) => {
  for (const deletedColumn of deletedColumns) {
    // Module groups/wrapper cells are not in layoutSectionWidgets, no need to delete them from there
    if (deletedColumn.isModule()) {
      const layoutSectionWidgetsPath = ['layoutSectionWidgets', layoutSectionId];
      const layoutSectionWidgets = get(draft, layoutSectionWidgetsPath);
      // TODO: Maybe use `original` here for better perf
      const layoutSectionWidgetsIndex = layoutSectionWidgets.findIndex(m => m.name === deletedColumn.getName());
      if (layoutSectionWidgetsIndex === -1) {
        console.warn(`Error deleting ${deletedColumn.getName()}, it is not in layout section widgets`);
        EditorMetricsTracker.counter('Deleting module missing in schema layout section widgets').increment();
        continue;
      }
      layoutSectionWidgets.splice(layoutSectionWidgetsIndex, 1);
    }
  }
};
const removeUncustomizedWrappersStartingAtColumn = (tree, column) => {
  const {
    descendent
  } = pathOfAllUnnecessaryWrappersUpToDescendentToKeep(column);

  // If there are unnecessary wrappers, move the descendent up to the original column's parent
  // (which will trigger those unncessary wrappers to be automatically deleted)
  if (descendent !== column) {
    if (!descendent.isCell()) {
      throw new Error('Error looking up unnecessary wrappers, descendent should be a column or module');
    }
    const originalParentRowName = column.getParentName();
    return tree.appendColumn(originalParentRowName, {
      existingCellName: descendent.getName()
    });
  }
  return {};
};
const removeWrappersFromSiblingsInSectionIfNeeded = (modifiedTree, deletedColumns) => {
  for (const col of deletedColumns) {
    if (modifiedTree.hasRow(col.getParentName())) {
      const newParentRow = modifiedTree.findRow(col.getParentName());
      const wasColDeletedFromSection = newParentRow.getParent().isRoot();
      const onlyHadOneSibling = newParentRow.getNumberColumns() === 1;

      // If we just deleted column that triggered a section to go from 2 to 1 columns, see if we
      // can remove any unecessary wrapper columns around the last sibling
      if (wasColDeletedFromSection && onlyHadOneSibling) {
        modifiedTree.printTreeWithNames();
        const {
          tree: newTree,
          deletedColumn: moreDeletedColumns
        } = removeUncustomizedWrappersStartingAtColumn(modifiedTree, newParentRow.getColumns()[0]);
        modifiedTree = newTree || modifiedTree;
        deletedColumns = deletedColumns.concat(moreDeletedColumns || []);
        return {
          newTree: modifiedTree,
          deletedColumns
        };
      }
    }
  }
  return {
    newTree: modifiedTree,
    deletedColumns
  };
};
export const removeCellFromLayoutSectionImmer = (draft, layoutSectionId, cellId, {
  shouldRemoveUnnecessaryWrappersForSingleColumnSection
}) => {
  // If this is the first time the layout section has been touched on the page, copy it to the content model
  copyLayoutSectionFromSchemaToContentIfNeededImmer(draft, layoutSectionId);
  const tree = getLayoutSectionTreeImmer(draft, layoutSectionId);
  const cell = tree.findCell(cellId);
  if (!tree || !cell) {
    throw new Error(`Error deleting ${cellId}, it is not in layout section ${layoutSectionId}`);
  }
  let {
    tree: newTree,
    deletedColumns /* , deletedRows */
  } = tree.removeCell(cellId);
  if (shouldRemoveUnnecessaryWrappersForSingleColumnSection) {
    ({
      newTree,
      deletedColumns
    } = removeWrappersFromSiblingsInSectionIfNeeded(newTree, deletedColumns));
  }
  set(draft, ['layoutSectionTrees', layoutSectionId], newTree);

  // Also delete all of the descendent modules from layoutSectionWidgets
  cleanupDeletedCellsFromLayoutSection(draft, deletedColumns, layoutSectionId);
};
export const removeRowFromLayoutSectionImmer = (draft, layoutSectionId, rowId) => {
  // If this is the first time the layout section has been touched on the page, copy it to the content model
  copyLayoutSectionFromSchemaToContentIfNeededImmer(draft, layoutSectionId);
  const tree = getLayoutSectionTreeImmer(draft, layoutSectionId);
  const row = tree.findRow(rowId);
  if (!tree || !row) {
    throw new Error(`Error deleting ${rowId}, it is not in layout section ${layoutSectionId}`);
  }
  const {
    tree: newTree,
    deletedColumns /* , deletedRows */
  } = tree.removeRow(rowId);
  set(draft, ['layoutSectionTrees', layoutSectionId], newTree);

  // Also delete all of the descendent modules from layoutSectionWidgets
  cleanupDeletedCellsFromLayoutSection(draft, deletedColumns, layoutSectionId);
};
const correctLayoutSectionWidgets = (draft, originLayoutSectionId, layoutSectionId, modifiedColumns) => {
  const originTreeList = get(draft, ['layoutSectionWidgets', originLayoutSectionId], []);
  const treeList = get(draft, ['layoutSectionWidgets', layoutSectionId], []);
  const modifiedColumnIds = modifiedColumns.map(column => column.getName());
  const movedWidgets = [];
  modifiedColumnIds.forEach(modifiedColumnId => {
    const movedWidget = originTreeList.find(widget => widget.name === modifiedColumnId);
    if (movedWidget) {
      movedWidget.layout_section_id = layoutSectionId;
      movedWidgets.push(movedWidget);
    }
  });
  if (movedWidgets.length) {
    const filteredOriginList = originTreeList.filter(widget => !modifiedColumnIds.includes(widget.name));
    set(draft, ['layoutSectionWidgets', originLayoutSectionId], filteredOriginList);
    treeList.push(...movedWidgets);
    // TODO: Be careful about this, some array methods don't return the
    // resultant array.
    set(draft, ['layoutSectionWidgets', layoutSectionId], treeList);
  }
};
export const updateLayoutSectionTreeAndWidgetsImmer = ({
  layoutSectionId,
  originLayoutSectionId,
  newModuleSchemaJson,
  customSectionModuleSchemas,
  modifiedColumns,
  tree,
  originTree,
  mapOfClonedToOldNodeName,
  draft
}) => {
  if (newModuleSchemaJson) {
    addNewModuleToLayoutSectionWidgets(draft, layoutSectionId, newModuleSchemaJson);
  }
  if (customSectionModuleSchemas) {
    customSectionModuleSchemas.forEach(schema => {
      addNewModuleToLayoutSectionWidgets(draft, layoutSectionId, schema);
    });
  }

  // Make sure that all cloned modules also show up in layout_section_widgets
  if (mapOfClonedToOldNodeName) {
    Object.keys(mapOfClonedToOldNodeName).forEach(newCloneName => {
      const oldName = mapOfClonedToOldNodeName[newCloneName];
      addClonedModuleToLayoutSectionWidgets(draft, layoutSectionId, oldName, newCloneName);
      // Keep track if its a static section row
      // `mapOfClonedToOldStaticSectionName` is Used in `ModuleDomRenderContainer`
      if (tree.hasRow(oldName) && tree.findRow(oldName).isStaticSection()) {
        draft.mapOfClonedToOldStaticSectionName = mapOfClonedToOldNodeName;
      }
      if (tree.hasCell(oldName)) {
        const treeNode = tree.findCell(oldName);
        if (treeNode && treeNode.isModule()) {
          draft.clonedModulesPendingDomOperations.add({
            originalModule: oldName,
            newModule: newCloneName
          });
        }
      }
    });
  }

  // Don't attempt to update / set the temporary tree from
  // a custom section into redux.
  if (originTree && originLayoutSectionId && !customSectionModuleSchemas && modifiedColumns) {
    set(draft, ['layoutSectionTrees', originLayoutSectionId], originTree);
    correctLayoutSectionWidgets(draft, originLayoutSectionId, layoutSectionId, modifiedColumns);
  }
  set(draft, ['layoutSectionTrees', layoutSectionId], tree);
};

// MODULES
const updateTinymceUndoDataForModule = (draft, id, tinymceUndoData) => {
  mergeIn(draft, ['moduleMetaData', id, 'tinymceUndoData'], tinymceUndoData);
};
export const updateMetaDataForModuleImmer = (draft, id, metaData = {}) => {
  // TODO branden maybe not the best way to do this
  // TODO: Swap out this shared action data with the actual action data from moduleReducerUtils once we're migrating the reducer fully
  if (get(SHARED_ACTION_DATA, [id, 'tinymceUndoData'])) {
    updateTinymceUndoDataForModule(draft, id, get(SHARED_ACTION_DATA, [id, 'tinymceUndoData']));
    unset(SHARED_ACTION_DATA, [id, 'tinymceUndoData']);
  }
  if (get(SHARED_ACTION_DATA, [id, 'inlineRichTextFieldEditingMetaData'])) {
    // Copying the pattern set by 'tinymceUndoData'
    // Make sure that we're merging into 'inlineRichTextFieldEditingMetaData'
    // as opposed to overwriting it
    // Then delete `inlineRichTextFieldEditingMetaData` so that the `mergeIn`
    // following this one doesn't overwrite the existing inlineRichTextFieldEditingMetaData
    mergeIn(draft, ['moduleMetaData', id, 'inlineRichTextFieldEditingMetaData'], get(SHARED_ACTION_DATA, [id, 'inlineRichTextFieldEditingMetaData']));
    unset(SHARED_ACTION_DATA, [id, 'inlineRichTextFieldEditingMetaData']);
  }
  mergeIn(draft, ['moduleMetaData', id], metaData);
};
export const incrementModuleEditVersionImmer = ({
  draft,
  id,
  defaultInitialVersion = 1
}) => {
  //The defaultInitialVersion handles a strange edge case where the image needs to be
  //resized on add and the moduleVersion would be set to 1 instead of 0
  //causing us not to be able to find the module on undo/redo
  let newVersion = defaultInitialVersion;
  const editVersionKey = ['moduleMetaData', id, 'editVersion'];
  const currentVersion = get(draft, editVersionKey);
  if (currentVersion != null) {
    newVersion = currentVersion + 1;
  }
  set(draft, editVersionKey, newVersion);
};

// This is used to change UI editable field values like "src" or "text" on a module
export const mergeModuleBodyHelperImmer = (draft, id, partialBody, {
  moduleDefaultType
}) => {
  const tree = findTreeWithCellNameImmer(draft, id);
  const isDefaultImageModule = moduleDefaultType === 'linked_image';
  if (tree) {
    copyLayoutSectionFromSchemaToContentIfNeededImmer(draft, tree.getRootName());

    // Make sure to get cell reference in newly modified tree
    let newTree = get(draft, ['layoutSectionTrees', tree.getRootName()]);
    const cell = tree.findCell(id);
    ({
      tree: newTree
    } = newTree.mergeIntoCellParams(cell.getName(), partialBody));
    if (isDefaultImageModule && cell.hasValue() && cell.getValue().merge_in_default_values == null) {
      ({
        tree: newTree
      } = newTree.mergeIntoCellValue(cell.getName(), {
        merge_in_default_values: false
      }));
    }
    set(draft, ['layoutSectionTrees', tree.getRootName()], newTree);
    return;
  }
  const moduleKey = getKeyForNonLayoutSectionImmer(draft, id);
  const moduleBodyKey = [...moduleKey, 'body'];
  const modulemergeInDefaultFieldValuesKey = [...moduleKey, 'merge_in_default_values'];
  mergeIn(draft, moduleBodyKey, partialBody);
  if (isDefaultImageModule && !has(draft, modulemergeInDefaultFieldValuesKey)) {
    set(draft, modulemergeInDefaultFieldValuesKey, false);
  }
};

// This is used to change top level data fields that we use like "Label" on a module
export const mergeModuleDataHelperImmer = (draft, id, partialData, layoutSectionId) => {
  const tree = findTreeWithCellNameImmer(draft, id);
  if (tree) {
    copyLayoutSectionFromSchemaToContentIfNeededImmer(draft, tree.getRootName());

    // Make sure to get cell reference in newly modified tree
    let newTree = get(draft, ['layoutSectionTrees', tree.getRootName()]);
    const cell = tree.findCell(id);
    ({
      tree: newTree
    } = newTree.mergeIntoCellValue(cell.getName(), partialData));
    const layoutSectionWidgets = get(draft, ['layoutSectionWidgets', layoutSectionId]);
    // TODO: Maybe use `original` here for a perf boost
    const widgetIndex = layoutSectionWidgets.findIndex(widget => widget.name === id);
    const newLayoutSectionWidgets = set(layoutSectionWidgets, [widgetIndex, 'label'], partialData.label);
    set(draft, ['layoutSectionWidgets', layoutSectionId], newLayoutSectionWidgets);
    set(draft, ['layoutSectionTrees', tree.getRootName()], newTree);
    return;
  }
  const moduleKey = getKeyForNonLayoutSectionImmer(draft, id);
  mergeIn(draft, moduleKey, partialData);
};
export const mergeLayoutFragmentDataHelperImmer = (draft, id, partialData, isRow) => {
  const tree = isRow ? findTreeWithRowNameImmer(draft, id) : findTreeWithCellNameImmer(draft, id);
  copyLayoutSectionFromSchemaToContentIfNeededImmer(draft, tree.getRootName());

  // Make sure to get cell reference in newly modified tree
  let newTree = get(draft, ['layoutSectionTrees', tree.getRootName()]);
  if (isRow) {
    const row = tree.findRow(id);
    ({
      tree: newTree
    } = newTree.mergeIntoRowValue(row.getName(), partialData));
  } else {
    const cell = tree.findCell(id);
    ({
      tree: newTree
    } = newTree.mergeIntoCellValue(cell.getName(), partialData));
  }
  set(draft, ['layoutSectionTrees', tree.getRootName()], newTree);
};
export const normalizeFlexColumnDataImmer = (contentFlexColumns, schemaFlexColumns = {}) => {
  if (contentFlexColumns) {
    const normalizedFlexColumns = {};
    const seen = {};

    // Ensure we keep the first instance of a named widget to keep in sync with the renderer,
    // so process each flex column in the render order
    const flexColumnIdsInOrder = Object.keys(schemaFlexColumns);
    flexColumnIdsInOrder.sort((a, b) => {
      const orderA = schemaFlexColumns[a] ? schemaFlexColumns[a].order : 0;
      const orderB = schemaFlexColumns[a] ? schemaFlexColumns[b].order : 0;
      return orderA - orderB;
    });
    flexColumnIdsInOrder.forEach(flexColumnId => {
      const flexColumn = contentFlexColumns[flexColumnId];
      if (flexColumn) {
        if (flexColumn.widgets) {
          const modules = [];
          flexColumn.widgets.forEach(module => {
            // Filter out any duplicate flex column widgets
            if (!seen[module.id]) {
              seen[module.id] = true;

              // In some cases, inside of a flex column, there can be a "nested" body object.
              // The renderer prefers the data in the nested body, so this attempts to replicate that behavior
              // We do it here instead of the selector because otherwise updates to the top level body would be
              // continually overwritten since the nested body takes precedence
              if (module.body && module.body.body && typeof module.body.body === 'object') {
                module.body = Object.assign({}, module.body, module.body.body);
                delete module.body.body;
              }

              // In some cases modules with smart content in a flex column will only have the
              // smart_objects list inside its body but not on the module itself. If that is the case
              // then this will copy over the body's smart_objects to the module.
              if (module.body && module.body.smart_objects && Array.isArray(module.body.smart_objects) && !module.smart_objects) {
                module.smart_objects = [...module.body.smart_objects];
              }
              modules.push(module);
            }
          });
          flexColumn.widgets = modules;
        }
        normalizedFlexColumns[flexColumnId] = flexColumn;
      }
    });
    return normalizedFlexColumns;
  }
  return contentFlexColumns;
};
export const maybeParseFlexAreaTreeImmer = ({
  content,
  isUngatedForDndAreasInCustomOrContentTreeSidebar,
  templateContainsFlexArea
}) => {
  const flexAreaTree = {};
  if (isUngatedForDndAreasInCustomOrContentTreeSidebar && templateContainsFlexArea) {
    const flexAreaData = parseFlexAreaDataForLayoutTree(content.flexAreas, content.widgets);
    flexAreaTree[DND_AREA_ID] = importTreeFromLayoutDataApi(flexAreaData, {
      shouldPreventEmptyRows: false,
      CellClass: CustomCellTweakingAutoDeleteLogicPerTestFlag
    });
  }
  return flexAreaTree;
};