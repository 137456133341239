import http from 'hub-http/clients/apiClient';
import { makeFrameworkDataSchemaResolversClient } from './makeFrameworkDataSchemaResolversClient';

// Sadly we cannot export { type } from './file'; yet :(

export { FrameworkTypeLabel } from './components/FrameworkTypeLabel';
export { PropertyLabel } from './components/PropertyLabel';
const clientMap = new WeakMap();
export const getFrameworkDataSchemasClient = async ({
  httpClient = http
} = {}) => {
  if (!clientMap.has(httpClient)) {
    clientMap.set(httpClient, makeFrameworkDataSchemaResolversClient({
      httpClient
    }));
  }

  // HACK: The WeakMap types say this can be undefined, but we will always be checking for/setting
  // the value on the lines above, so I asserted the type.
  return await clientMap.get(httpClient);
};
export { __TEST_ONLY__DISABLE_IDB } from './indexeddb/indexeddb';