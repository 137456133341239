import { useEffect, useMemo, useRef, useState } from 'react';
import { getFrameworkDataSchemasClient } from '..';
import { stableHash } from '../utils/stableHash';
export const makeUseClient = (getClientMethod, httpClient, __TEST_ONLY__getSchemasClient) => args => {
  const skip = !!(args && args.skip);
  const variables = args && args.variables;
  const [asyncState, setAsyncState] = useState({
    loading: !skip
  });
  const stringifiedVariables = useMemo(() => stableHash({
    skip,
    variables
  }), [skip, variables]);
  const variablesRef = useRef('');
  variablesRef.current = stringifiedVariables;
  useEffect(() => {
    if (!skip) {
      setAsyncState({
        loading: true
      });
      (__TEST_ONLY__getSchemasClient || getFrameworkDataSchemasClient)({
        httpClient
      }).then(client => getClientMethod(client)(variables)).then(results => {
        if (variablesRef.current === stringifiedVariables) {
          setAsyncState({
            data: results,
            loading: false
          });
        }
      }).catch(err => {
        if (variablesRef.current === stringifiedVariables) {
          setAsyncState({
            loading: false,
            error: err
          });
        }
      });
    }
    // HACK: stringifiedVariables is a proxy for a deep equals on variables + skip, so we
    // can omit them from the dependencies array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedVariables]);
  return asyncState;
};