import { useCallback, useEffect, useState } from 'react';
import { getFrameworkAppSettings } from '../fasApi';
import { COLLABORATION_SIDEBAR_FAS_NAMESPACE, COMMENTING_FAS_NAMESPACE } from '../constants';
import useHasFASCommenting from './useHasFASCommenting';
import { LEGACY_COMMENT_OBJECT_TYPES } from '../../../constants/CommentObjectType';
export default function useFrameworkAppSettings() {
  const [loading, setIsLoading] = useState(true);
  const [appSettings, setAppSettings] = useState(null);
  const isUngatedForFASCommenting = useHasFASCommenting();
  const getCollaborationSidebarAppSetting = useCallback(appName => {
    var _appSettings$appName;
    return (appSettings === null || appSettings === void 0 || (_appSettings$appName = appSettings[appName]) === null || _appSettings$appName === void 0 || (_appSettings$appName = _appSettings$appName[COLLABORATION_SIDEBAR_FAS_NAMESPACE]) === null || _appSettings$appName === void 0 ? void 0 : _appSettings$appName.metadata) || null;
  }, [appSettings]);
  const getCommentingAppSetting = useCallback(objectTypeId => {
    var _appSettings$objectTy;
    if (LEGACY_COMMENT_OBJECT_TYPES.includes(objectTypeId)) {
      return {
        enabled: {
          value: true
        }
      };
    }
    return (appSettings === null || appSettings === void 0 || (_appSettings$objectTy = appSettings[objectTypeId]) === null || _appSettings$objectTy === void 0 || (_appSettings$objectTy = _appSettings$objectTy[COMMENTING_FAS_NAMESPACE]) === null || _appSettings$objectTy === void 0 ? void 0 : _appSettings$objectTy.metadata) || null;
  }, [appSettings]);
  useEffect(() => {
    setIsLoading(true);
    getFrameworkAppSettings({
      isUngatedForFASCommenting
    }).then(response => {
      setAppSettings(response);
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
  }, [isUngatedForFASCommenting]);
  return {
    getCollaborationSidebarAppSetting,
    getCommentingAppSetting,
    loading
  };
}