import I18n from 'I18n';
import { isEmptyPassword, isPasswordTooShort, hasNoAccessGroupsSelected } from 'ui-memberships-settings-lib/utils/publicAccessRuleValidation';
import { MIN_PASSWORD_LENGTH } from 'ui-memberships-settings-lib/constants/contentAccessControl';
import DEEP_LINK_QUERIES from './pageSettingsDeepLinkQueries';
import { isTemporarySlug } from '../utils/contentModelURLUtils';
const CRM_SOURCE_TYPE = 2;
const pageSettingsErrors = (contentModel, pageSettings, authData, primaryDomain, contentModelHasValidDomain, isAllowedToPublishWithHubdb, hasCurrentLanguageInCurrentTranslatedContent, requireHtmlTitle = true, hasInvalidDynamicPageSourceValue, cannotUseCrmObjectTypeBecauseRestricted, isPublishOptionsModalOpen) => {
  const errors = {};
  const isUngatedForPatagonia = authData.get('isUngatedForPatagonia');
  const getDeepLinkQuery = query => isUngatedForPatagonia ? query : null;
  const hasAudienceAccess = authData.get('hasAudienceAccessWrite');
  const userHasPublishPrivateContentAccess = authData.get('userHasPublishPrivateContentAccess');
  const contentHasTemporarySlug = isTemporarySlug(contentModel.get('slug'));
  if (!contentModel.get('name')) {
    errors.name = {
      pane: 'settings',
      type: 'required',
      fieldSchema: {
        title: I18n.text('components.header.validationErrorPopover.pageSettingsErrors.name')
      },
      settingsModalQuery: getDeepLinkQuery(DEEP_LINK_QUERIES.GENERAL.NAME)
    };
  }
  if (requireHtmlTitle && !contentModel.get('htmlTitle') && !contentModel.get('mabVariant')) {
    errors.htmlTitle = {
      pane: 'settings',
      type: 'required',
      fieldSchema: {
        title: I18n.text('components.header.validationErrorPopover.pageSettingsErrors.htmlTitle')
      },
      settingsModalQuery: getDeepLinkQuery(DEEP_LINK_QUERIES.GENERAL.TITLE)
    };
  }
  if (!hasAudienceAccess && pageSettings.get('requirePassword') && isEmptyPassword(contentModel.get('password'))) {
    errors.password = {
      pane: 'settings',
      type: 'required',
      settingsModalQuery: getDeepLinkQuery(DEEP_LINK_QUERIES.ROLE_AND_ACCESS.PASSWORD)
    };
  }
  const isEditingPassword = contentModel.get('password') !== null;
  if (hasAudienceAccess && isEditingPassword && isEmptyPassword(contentModel.get('password'))) {
    errors.audienceAccess = {
      pane: 'settings',
      type: 'passwordAudienceAccess',
      target: 'audienceAccess',
      settingsModalQuery: getDeepLinkQuery(DEEP_LINK_QUERIES.ROLE_AND_ACCESS.PASSWORD)
    };
  }
  if (hasAudienceAccess && isEditingPassword && isPasswordTooShort(contentModel.get('password'))) {
    errors.audienceAccess = {
      pane: 'settings',
      type: 'passwordAudienceAccessTooShort',
      target: 'audienceAccess',
      settingsModalQuery: getDeepLinkQuery(DEEP_LINK_QUERIES.ROLE_AND_ACCESS.PASSWORD),
      options: {
        minLength: MIN_PASSWORD_LENGTH
      }
    };
  }
  const publicAccessRules = contentModel.get('publicAccessRules');
  const isInvalidNoAccessGroupsSelected = hasNoAccessGroupsSelected(publicAccessRules);
  if (hasAudienceAccess && userHasPublishPrivateContentAccess && isInvalidNoAccessGroupsSelected) {
    errors.audienceAccess = {
      pane: 'settings',
      type: 'accessGroupsRequired',
      target: 'audienceAccess',
      settingsModalQuery: getDeepLinkQuery(DEEP_LINK_QUERIES.ROLE_AND_ACCESS.PASSWORD)
    };
  }

  // pageUnpublishDate and pageUnpublishRedirectUrl are errors related to the page expiry field
  // in Patagonia, they only exist within the modal, so we don't want them affecting the non-modal button state
  if (contentModel.get('pageExpiryEnabled') && !contentModel.get('pageExpiryDate') && (!isUngatedForPatagonia || isUngatedForPatagonia && isPublishOptionsModalOpen)) {
    errors.pageUnpublishDate = {
      pane: 'publishing',
      type: 'required'
    };
  }
  if (contentModel.get('pageExpiryEnabled') && !contentModel.get('pageExpiryRedirectUrl') && (!isUngatedForPatagonia || isUngatedForPatagonia && isPublishOptionsModalOpen)) {
    errors.pageUnpublishRedirectUrl = {
      pane: 'publishing',
      type: 'required'
    };
  }
  if (contentModel.get('useFeaturedImage') && !contentModel.get('featuredImage')) {
    errors.featuredImage = {
      pane: 'settings',
      type: 'required',
      fieldSchema: {
        title: I18n.text('components.header.validationErrorPopover.pageSettingsErrors.featuredImage')
      },
      settingsModalQuery: getDeepLinkQuery(DEEP_LINK_QUERIES.IMAGE.FEATURED_IMAGE)
    };
  }
  if (contentModel.get('language') && hasCurrentLanguageInCurrentTranslatedContent) {
    errors.language = {
      pane: 'settings',
      type: 'language',
      target: 'language',
      settingsModalQuery: getDeepLinkQuery(DEEP_LINK_QUERIES.LANG_AND_REGION.LANGUAGE)
    };
  }
  if (hasInvalidDynamicPageSourceValue) {
    errors.hasInvalidDynamicPageSourceValue = {
      pane: 'settings',
      type: 'hasInvalidDynamicPageSourceValue',
      target: 'dynamicPages',
      settingsModalQuery: getDeepLinkQuery(DEEP_LINK_QUERIES.ADVANCED.DYNAMIC_PAGES)
    };
  } else if (cannotUseCrmObjectTypeBecauseRestricted) {
    errors.cannotUseCrmObjectTypeBecauseRestricted = {
      pane: 'settings',
      type: 'cannotUseCrmObjectTypeBecauseRestricted',
      target: 'dynamicPages',
      settingsModalQuery: getDeepLinkQuery(DEEP_LINK_QUERIES.ADVANCED.DYNAMIC_PAGES)
    };
  } else if (contentModel.get('dynamicPageDataSourceId') && contentModel.get('dynamicPageDataSourceType') === CRM_SOURCE_TYPE && !contentModel.get('propertyForDynamicPageSlug')) {
    errors.noValueForDynamicPageSlug = {
      pane: 'settings',
      type: 'required',
      fieldSchema: {
        title: I18n.text('components.header.validationErrorPopover.pageSettingsErrors.dynamicPageSlug')
      },
      target: 'dynamicPages',
      settingsModalQuery: getDeepLinkQuery(DEEP_LINK_QUERIES.ADVANCED.DYNAMIC_PAGES)
    };
  }
  if (!isAllowedToPublishWithHubdb) {
    errors.noHubDbCustomDomainAccess = {
      pane: 'settings',
      type: 'noHubDbCustomDomainAccess',
      target: 'dynamicPages',
      settingsModalQuery: getDeepLinkQuery(DEEP_LINK_QUERIES.ADVANCED.DYNAMIC_PAGES)
    };
  }
  if (!primaryDomain) {
    errors.slug = {
      pane: 'settings',
      type: 'primaryDomainMissing',
      target: 'slug',
      settingsModalQuery: getDeepLinkQuery(DEEP_LINK_QUERIES.GENERAL.SLUG)
    };
  }
  if (contentHasTemporarySlug) {
    errors.slug = {
      type: 'temporarySlugNotAllowed',
      pane: 'settings',
      settingsModalQuery: getDeepLinkQuery(DEEP_LINK_QUERIES.GENERAL.TITLE),
      target: 'slug'
    };
  }
  if (!contentModelHasValidDomain) {
    errors.domain = {
      pane: 'settings',
      type: 'invalidDomain',
      target: 'slug',
      settingsModalQuery: getDeepLinkQuery(DEEP_LINK_QUERIES.GENERAL.SLUG)
    };
  }
  return errors;
};
export default pageSettingsErrors;