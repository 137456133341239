import { consoleIfNotProd } from 'chatspot-core/loggers/consoleIfNotProd';
const TEXT_GENERATION = 'text_generate';
const CONTENT_REWRITE = 'content_rewrite';

// Current hacky solution
export const getContentAssistantContextAsStringForCopilot = rawResponse => {
  if (!rawResponse) {
    return '';
  }
  const {
    editorContext,
    selectedText
  } = rawResponse;
  const textArray = [];
  if (editorContext) {
    textArray.push(`EDITOR TEXT: ${editorContext}`);
  }
  if (selectedText) {
    textArray.push(`SELECTED TEXT: ${selectedText}`);
    // Otherwise we're dealing with a cursor position
  }
  if (editorContext && !selectedText) {
    textArray.push(`<<>> : is the cursor position of EDITOR TEXT`);
  }
  return textArray.join('\n');
};
const getInitialPromptData = ({
  intent,
  parameters,
  messageText
}) => {
  // Allows consumer to override if they have specific text they want.
  const {
    promptPrefix = '',
    promptThread = ''
  } = parameters || {};
  const commandName = messageText.toLocaleLowerCase();
  switch (intent) {
    // If apps consuming copilot collab customize which intent they use, those cases
    // need to be added here as well
    case TEXT_GENERATION:
      return {
        commandName,
        // Path to update on the action object
        path: ['data', 'prompt'],
        // The message to prompt the user what to write
        promptPrefix,
        // The message to prompt the user what to write
        promptThread
      };
    // This is the ... more options from selection command.
    case CONTENT_REWRITE:
      return {
        commandName,
        path: ['data', 'operation'],
        promptPrefix,
        promptThread
      };
    default:
      consoleIfNotProd.error(`No prompt data found for intent: ${intent}. Try adding it to the switch statement.`);
      return undefined;
  }
};
export const getCopilotActionFromContentAssistantData = ({
  messageText,
  contentAssistantData,
  customIntent
}) => {
  if (!contentAssistantData) {
    return null;
  }
  const {
    commandId: __commandId,
    parameters,
    editorContext,
    selectedText
  } = contentAssistantData;
  if (selectedText) {
    const rewriteData = {
      title: messageText,
      data: {
        intent: customIntent !== null && customIntent !== void 0 ? customIntent : CONTENT_REWRITE,
        text: selectedText,
        // TODO: make this dynamic based on commandId AND parameters
        operation: messageText,
        editorText: editorContext
      }
    };
    if (parameters !== null && parameters !== void 0 && parameters.promptForMoreInfo) {
      rewriteData.promptData = getInitialPromptData({
        intent: customIntent !== null && customIntent !== void 0 ? customIntent : CONTENT_REWRITE,
        messageText,
        parameters
      });
    }
    return rewriteData;
  }
  return {
    // Will be set by the user
    title: '',
    data: {
      intent: customIntent !== null && customIntent !== void 0 ? customIntent : TEXT_GENERATION,
      prompt: messageText,
      editorText: editorContext
    },
    promptData: getInitialPromptData({
      intent: customIntent !== null && customIntent !== void 0 ? customIntent : TEXT_GENERATION,
      messageText,
      parameters
    })
  };
};