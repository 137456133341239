export const TOPIC_NAMES = {
  CLOSE_COPILOT: 'CLOSE_COPILOT',
  OPEN_COPILOT: 'OPEN_COPILOT',
  OPEN_ACTION_FORM: 'OPEN_ACTION_FORM',
  OPEN_FULLSCREEN_MESSAGE_VIEWER: 'OPEN_FULLSCREEN_MESSAGE_VIEWER',
  SEND_ACTION: 'SEND_ACTION',
  SEND_MESSAGE: 'SEND_MESSAGE',
  COLLABORATE_COPILOT_ACTON: 'COLLABORATE_COPILOT_ACTON',
  COLLABORATE_COPILOT_GET_CONTEXT: 'COLLABORATE_COPILOT_GET_CONTEXT',
  COLLABORATE_PARENT_APP_MESSAGE: 'COLLABORATE_PARENT_APP_MESSAGE',
  PROMPT_USER_FOR_MORE_INFO: 'PROMPT_USER_FOR_MORE_INFO'
};