import { useEffect, useMemo, useRef } from 'react';
import isEqual from 'hs-lodash/isEqual';
import { FEATURES } from 'collaboration-sidebar/constants/features';
import usePrevious from 'collaboration-sidebar/hooks/usePrevious';
import useHasFASCommenting from 'collaboration-sidebar/features/fas/hooks/useHasFASCommenting';
import { hasFeature } from '../utils/features';
import { mergeCollaborationSidebarState } from '../utils';
import useGetPropsFromFAS from '../features/fas/hooks/useGetPropsFromFAS';

/**
 * Today, several prop values (such as objectId ,objectType, ctaUrl)
 * are duplicated in CollaborationSidebarState, where they can be read
 * by the mini commenter. To ensure these values are up to date, we
 * sync into the state from props.
 */
export default function usePersistPropsToCollaborationSidebarState({
  collaborationSidebarState,
  onUpdateCollaborationSidebarState,
  collaborationSidebarProps: {
    objectId,
    objectType,
    appName,
    enabledFeatures,
    ctaUrl = window.location.href
  }
}) {
  const {
    fasProps
  } = useGetPropsFromFAS({
    appName: appName || '',
    enabledFeatures
  });
  const isUngatedForFASCommenting = useHasFASCommenting();
  const isEmbeddedCommentingEnabled = hasFeature(fasProps === null || fasProps === void 0 ? void 0 : fasProps.enabledFeatures, FEATURES.DEEP_COMMENTING);
  const updatesToPersist = useMemo(() => ({
    isEmbeddedCommentingEnabled,
    appName,
    objectId,
    objectType: isUngatedForFASCommenting || fasProps !== null && fasProps !== void 0 && fasProps.objectType ? fasProps === null || fasProps === void 0 ? void 0 : fasProps.objectType : objectType,
    ctaUrl,
    enabledFeatures: (fasProps === null || fasProps === void 0 ? void 0 : fasProps.enabledFeatures) || []
  }), [appName, ctaUrl, fasProps === null || fasProps === void 0 ? void 0 : fasProps.enabledFeatures, fasProps === null || fasProps === void 0 ? void 0 : fasProps.objectType, isEmbeddedCommentingEnabled, objectId, objectType, isUngatedForFASCommenting]);
  const prevUpdatesToPersist = usePrevious(updatesToPersist);
  const mounted = useRef(false);

  /**
   * Persist prop updates to state
   */
  useEffect(() => {
    if (!mounted.current || !isEqual(prevUpdatesToPersist, updatesToPersist)) {
      onUpdateCollaborationSidebarState(updatesToPersist);
    }
    mounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevUpdatesToPersist, updatesToPersist]);

  /**
   * As an optimization, return updated version (avoid unnecessary prop updates in iframe)
   */
  return useMemo(() => mergeCollaborationSidebarState(collaborationSidebarState, updatesToPersist), [collaborationSidebarState, updatesToPersist]);
}