import enviro from 'enviro';
import { LOCAL_COPILOT } from '../constants/DevelopmentConstants';
const topicNameErrorMap = {};
export const validateEnvironment = topicName => {
  // This is a dev-ex tool, don't check in prod
  if (enviro.isProd()) {
    return;
  }

  // Only send one warning per topic name
  if (topicNameErrorMap[topicName]) {
    return;
  } else {
    topicNameErrorMap[topicName] = true;
  }
  if (!enviro.deployed() && !enviro.debug(LOCAL_COPILOT)) {
    console.warn(`You are not running copilot locally with your app and something tried to emit a ${topicName} copilot event. This can cause issues with anything that interacts with copilot. If you want to test copilot locally, make sure you run enviro.setDebug("LOCAL_COPILOT", true) and run all the iframes in your app that would impact your local development.`);
  }
  if (enviro.deployed() && enviro.debug(LOCAL_COPILOT)) {
    console.warn(`You are running copilot locally but part of your app is not running locally and something tried to emit a ${topicName} copilot event. This can cause issues with anything that interacts with copilot. If you want to test copilot locally, make sure you run enviro.setDebug("LOCAL_COPILOT", true) and run all the iframes in your app that would impact your local development.`);
  }
};