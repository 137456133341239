import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import retryClient from './api/retryClient';
import { setGroupKey } from './api/userContextApi';
import { setOnboardingSettings } from './onboardingSettings';
export const USER_CONTEXT_ACTIONS_BASE_URL = 'usercontext/v1/actions';
export const USER_CONTEXT_APP_BASE_URL = 'usercontext-app/v1';
export const enableTaskCompletionModal = (actionName, options = {}) => {
  const {
    targetWindow = window
  } = options;
  const bannerFrame = targetWindow.document.getElementById('onboarding-tours-banner-frame');
  if (!window.sessionStorage || !bannerFrame || !bannerFrame.contentWindow) {
    return;
  }
  try {
    const configKey = 'ONBOARDING_TOURS_GUIDE';
    const configJson = window.sessionStorage.getItem(configKey);
    if (!configJson) {
      return;
    }
    const config = JSON.parse(configJson);
    if (config.type === 'task' && config.key === actionName) {
      config.completed = true;
      bannerFrame.contentWindow.postMessage({
        key: configKey,
        value: config
      }, '*');
    }
  } catch (e) {
    return;
  }
};
/**
 * Look for the Growth Side Panel iframe in the document
 *
 * @param targetWindow window where we expect to find the Growth Side Panel iframe
 * @returns iframe content window if found, null otherwise
 */
export const getIframeContentWindow = (targetWindow = window) => {
  var _targetWindow$documen;
  const iframeElement = targetWindow === null || targetWindow === void 0 || (_targetWindow$documen = targetWindow.document) === null || _targetWindow$documen === void 0 ? void 0 : _targetWindow$documen.getElementById('growth-side-panel-help-widget-iframe');
  return (iframeElement === null || iframeElement === void 0 ? void 0 : iframeElement.contentWindow) || null;
};

/**
 * Look for the Growth Side Panel content UI in the document
 *
 * @param targetWindow window where we expect to find the Growth Side Panel content-ui
 * @returns targetWindow if GSP content-ui is present, null otherwise
 */
export const getGrowthSidePanelContentUIWindow = (targetWindow = window) => {
  var _targetWindow$documen2;
  const contentUiElement = targetWindow === null || targetWindow === void 0 || (_targetWindow$documen2 = targetWindow.document) === null || _targetWindow$documen2 === void 0 ? void 0 : _targetWindow$documen2.getElementById('growth-side-panel-content-ui-container');
  return contentUiElement ? targetWindow : null;
};
export const sendTaskUpdateToSidePanel = ({
  actionName,
  targetWindow = window,
  origin = targetWindow.location.origin
}) => {
  const gspWindow = getIframeContentWindow(targetWindow) || getGrowthSidePanelContentUIWindow(targetWindow);
  if (!(gspWindow !== null && gspWindow !== void 0 && gspWindow.postMessage)) {
    return;
  }
  try {
    gspWindow.postMessage({
      type: 'growth-side-panel',
      payload: {
        key: actionName,
        action: 'task-completed'
      }
    }, origin);
  } catch (e) {
    return;
  }
};

/**
 * Post a message when the user completes/exits a task
 * growth-side-panel-messaging has a corrensponding onboardingTask messages constant to listen to this
 * https://git.hubteam.com/HubSpot/growth-side-panel/blob/master/growth-side-panel-messaging/static/js/onboardingTask/constants.ts#L6
 */
export const sendTaskExitMessage = ({
  actionName,
  origin = window.location.origin
}) => {
  try {
    window.postMessage({
      type: 'growth-side-panel',
      payload: {
        key: actionName,
        action: 'task-exit'
      }
    }, origin);
  } catch (e) {
    return;
  }
};

/**
 * Auto reopen Growth Side Panel when task is completed for the task which is started from the side panel
 * The `growth-side-panel-ongoing-tasks:${portalId}` is set in `growth-side-panel-content-ui` when the task cta link is clicked
 * @param actionName task key
 * @param autoOpenSidePanel
 *   true: set localStorage and call zorse.openHelpWidget
 *   'onNextPage': only set localStorage
 */
export const openSidePanelOnAutoOpenTask = (actionName, autoOpenSidePanel = true) => {
  try {
    const portalId = PortalIdParser.get();

    // control the reopening of Growth Side Panel
    const iahAutoOpenKey = `in-app-help-auto-open-widget:${portalId}`;

    // keep track of tasks started in Growth Side Panel
    const ongoingTaskStorageKey = `growth-side-panel-ongoing-tasks:${portalId}`;
    const ongoingTasksRegisterRaw = window.localStorage.getItem(ongoingTaskStorageKey);
    if (!ongoingTasksRegisterRaw) {
      return;
    }
    const ongoingTasksRegister = JSON.parse(ongoingTasksRegisterRaw);
    const {
      time: taskStartTime
    } = ongoingTasksRegister[actionName];

    // cleanup storage
    if (taskStartTime) {
      delete ongoingTasksRegister[actionName];
      window.localStorage.setItem(ongoingTaskStorageKey, JSON.stringify(ongoingTasksRegister));
    }
    const oneHour = 60 * 60 * 1000;
    if (!taskStartTime || Date.now() - taskStartTime > oneHour) {
      return;
    }
    const tomorrow = Date.now() + oneHour * 24;
    window.localStorage.setItem(iahAutoOpenKey, tomorrow.toString());
    if (autoOpenSidePanel === true) {
      const {
        zorse
      } = window.hubspot || {};
      if (zorse && typeof zorse.openHelpWidget === 'function') {
        zorse.openHelpWidget();
      }
    }

    // send a message to trigger survey from growth side panel on task completion/exit
    sendTaskExitMessage({
      actionName
    });
  } catch (_unused) {
    // ignore error
  }
};

/**
 *  @param actionName task key
 *  @param options.autoOpenSidePanel
 *    false: ignore auto open side panel
 *    true: set localStorage and call zorse.openHelpWidget
 *    'onNextPage': only set localStorage
 *  @param options.showTaskCompletionModal show task completion modal from handholding banner
 */
export const markActionComplete = (actionName, options = {}) => {
  return retryClient.post(USER_CONTEXT_ACTIONS_BASE_URL, {
    data: {
      actionName
    }
  }).then(() => {
    const {
      autoOpenSidePanel = true,
      showTaskCompletionModal = true,
      targetMessageWindow = window,
      source
    } = options;
    if (autoOpenSidePanel) {
      openSidePanelOnAutoOpenTask(actionName, autoOpenSidePanel);
    }
    // send a message to the Growth Side Panel if present in the page
    sendTaskUpdateToSidePanel({
      actionName,
      targetWindow: targetMessageWindow
    });
    if (!showTaskCompletionModal) {
      return;
    }

    /**
     * Ignore task completion modal for avoiding breaking onboarding tour when:
     *   - Any onboarding tour is active in current page
     *   - markActionComplete is not triggered from onboarding-tours lib
     * */
    if (source !== 'onboarding-tours' && document.querySelector('.onboarding-tour--active')) {
      return;
    }
    enableTaskCompletionModal(actionName, {
      targetWindow: targetMessageWindow
    });
  });
};
export const getCompletedActions = () => http.get(USER_CONTEXT_ACTIONS_BASE_URL);
export const getIsActionCompleted = actionName => http.get(`${USER_CONTEXT_ACTIONS_BASE_URL}/${actionName}`);
export const getTasksGroup = () => {
  return http.get(`${USER_CONTEXT_APP_BASE_URL}/onboarding/tasks/group`, {
    headers: {
      accept: 'text/plain'
    }
  });
};
export const setDefaultGroupKey = (groupKey, options) => setGroupKey(groupKey, options);
export const setGettingStartedGroupKey = (groupKey, view, userId, portalId) => {
  const groupKeyRequests = [setDefaultGroupKey(groupKey)];
  if (view && userId) {
    groupKeyRequests.push(setOnboardingSettings({
      views: {
        [view]: {
          groupKey
        }
      },
      selectedView: view
    }, userId, portalId));
  }
  return Promise.all(groupKeyRequests);
};